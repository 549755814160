<template>
  <el-card v-if="dialogType === 'apply'">
    <template #header>
      <div class="card-header" style="display: flex; justify-content: space-between;">
        <span class="SubHead">开户申请</span>
        <span class="LinkText" style="cursor: pointer;" @click="dialogType = ''">返回</span>
      </div>
    </template>
    <apply-account @close="dialogType = ''" :editable="true" />
  </el-card>
  <template v-else>
    <el-row class="mb-20 firstRow">
      <el-col :span="16">
        <el-card class="box-card accountNum">
          <template #header>
            <el-row class="card-header" justify="space-between">
              <span class="SubHead">{{ accountNumInfo.title }}</span>
              <el-link type="primary"
                :disabled="undefined === accountNumInfo.active_rate || accountNumInfo.active_rate < 0"
                :underline="false" @click="dialogType = 'apply'" v-rolePermissionBtn="'open_account'">
                新增广告账号
              </el-link>
            </el-row>
          </template>

          <div class="text item">
            <ul>
              <el-row :gutter="20">
                <el-col :span="6" v-for="item in accountNumInfo.accountState" :key="item.id">
                  <li class="accountList">
                    <p class="Text">
                      {{ item.title }}
                      <span v-if="accountNumInfo.isshow">
                        <el-tooltip class="item" effect="light" content="" placement="top">
                          <template #content>
                            {{ item.tips }}
                          </template>
                          <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                      </span>
                    </p>
                    <p class="MainHead">{{ item.total }}</p>
                  </li>
                </el-col>
              </el-row>
            </ul>
          </div>
        </el-card>
      </el-col>

      <el-col :span="8">
        <balance-card ref="balanceRef" type="bing" />
      </el-col>
    </el-row>
    <el-row v-loading="$store.getters.isLoading">
      <el-col>
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span class="SubHead">所有账户( {{ accountNumInfo.total_num }} )</span>
            </div>
          </template>
          <search-form-content ref="searchFormRef" :fields="fields" :form-items="items" :default-page-size="5"
            :fetch-data="fetchData" :format-status="formatStatus" :table-type="'selection'" :row-key="'account_id'"
            :selected-data="selectedData" @selection-change="handleSelectChange">
            <template #buttons>
              <el-button type="text" class="LinkText" @click="dialogType = 'download'">导出账户消耗</el-button>
            </template>
            <template #selected>
              <el-col :span="12" style="padding-top: 15px" class="operate-row">
                <el-button :type="selectedData.count > 0 ? 'primary' : ''" size="mini"
                  @click="selectedData.count > 0 ? (dialogType = 'recharge') : ElMessage.warning('请选择广告账户')"
                  :disabled="selectedData.count === 0">
                  账户充值
                </el-button>

                <!-- 不让财务使用暂时隐藏 -->
                <!-- <el-button -->
                <!-- :type="selectedData.count > 0 ? 'primary' : ''" -->
                <!-- size="mini" -->
                <!-- @click="selectedData.count > 0 ? resetAccount() : ElMessage.warning('请选择广告账户')" -->
                <!-- > -->
                <!-- 余额清零 -->
                <!-- </el-button> -->
                <span class="Text" style="margin-left: 15px">已选择 {{ selectedData.count }} 个</span>
              </el-col>
            </template>
          </search-form-content>
        </el-card>
      </el-col>
    </el-row>
  </template>

  <download-dialog v-if="dialogType === 'download'" @close="dialogType = ''" />

  <recharge v-if="dialogType === 'recharge'" :act-ids="selectedData.items"
    :balance="balanceRef.getBalance ? balanceRef.getBalance() : 0" :recharge="rechargeSubmit" @refresh="refreshPageData"
    @close="dialogType = ''" />
</template>

<script setup>
import { ref } from 'vue'
import ApplyAccount from '@/views/account/manage/bing/ApplyAccount'
import SearchFormContent from '@/components/base/SearchFormContent'
import filters from '@/base/filters/base'
import api from '@/request/api/account'
import BalanceCard from '@/views/account/common/BalanceCard'
import DownloadDialog from '@/views/account/manage/bing/DownloadDialog'
import Recharge from '@/views/account/common/Recharge'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
import base from '@/base/filters/base'

const dialogType = ref('')
let balanceRef = ref('')
let searchFormRef = ref('')
let credit_type = ref('')
const accountNumInfo = ref({
  total_num: 0,
  title: '账户数量情况',
  active_rate: 0,
  accountState: [],
  isshow: false,
})

const items = {
  timeRange: {
    type: 'daterange',
    label: '选择日期',
    value: filters.getDefaultDuration(),
    disableDate: (time) => {
      return time.getTime() < 1640966400 * 1000 || time.getTime() > Date.now() - 3600 * 1000 * 24
    },
  },
  c_id: { label: 'CID' },
  account_id: { label: 'XID/XID账户名' },
  account_status: {
    type: 'selector',
    label: '账户状态',
    options: [
      { id: 1, name: '正常' },
      { id: 2, name: '非正常' },
    ],
  },
  company: { label: '营业执照' },
}

const fields = [
  { id: 'name', label: 'XID账户名称', fixed: 'left' },
  { id: 'account_id', label: 'XID' },
  { id: 'number', label: 'Number' },
  { id: 'company', label: '营业执照' },
  { id: 'c_id', label: 'CID' },
  { id: 'c_number', label: 'CNumber' },
  { id: 'account_status', label: '账户状态' },
  {
    id: 'spent',
    label: '消耗',
    sortable: true,
    format: (spent) => {
      return filters.thousands(spent)
    },
  },
  {
    id: 'balance',
    label: '账户余额',
    sortable: true,
    format: (balance) => {
      return filters.thousands(balance)
    },
  },
]

const formatStatus = (item) => {
  if ('account_status' !== item.column.property) return ''
  if ('正常' === item.row.account_status) return 'color-success'
  if ('待审核' === item.row.account_status) return 'color-primary'
  return 'color-danger'
}
const fetchData = async (params) => {
  let res = await api.bing.getAccountList(params)
  getAccountInfo()
  credit_type.value = res.data && res.data.credit_type ? res.data.credit_type : ''
  return {
    page: {
      total: res.data && res.data.total ? res.data.total : 0,
      page: params.page ? params.page : 1,
      pagesize: params.pagesize ? params.pagesize : 5,
    },
    data: res.advertising && res.advertising.length > 0 ? res.advertising : [],
  }
}

const getAccountInfo = async () => {
  let res = await api.bing.getAccountInfo()
  accountNumInfo.value = Object.assign({ total_num: res.data.total_num }, res.data.accountStateItem)
}

const selectedData = ref({
  count: 0,
  items: [],
})

let tableSelectable = (row) => {
  return row.account_status === '正常'
}

let handleSelectChange = (event) => {
  selectedData.value.items = []
  event.forEach((item) => {
    selectedData.value.items.push(item.account_id)
  })

  selectedData.value.count = selectedData.value.items.length
}

let rechargeSubmit = async (params) => {
  return await api.bing.rechargeAccount(params)
}

let refreshPageData = () => {
  dialogType.value = ''
  if (searchFormRef.value && searchFormRef.value.refreshData && searchFormRef.value.getSearchForm) {
    searchFormRef.value.refreshData(searchFormRef.value.getSearchForm(), false, false)
  }
  if (balanceRef.value && balanceRef.value.refreshBalance) {
    balanceRef.value.refreshBalance()
  }
}

let resetAccount = async () => {
  let msg = `你确定要将所选的${selectedData.value.count}个账户清零吗？`

  if (credit_type.value == 1)
    msg = `你确定要将所选的${selectedData.value.count}个账户清零吗？请注意，只有广告系列已暂停6小时的广告账户才能够被成功清零。由于微软广告的特殊性，余额清零操作将在30分钟后才能够查询到结果。若您已绑定邮箱，查询结果将发送到您的邮箱，请您注意查收。`

  const confirmResult = await ElMessageBox.confirm(`${msg}`, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).catch((err) => err)

  if (confirmResult !== 'confirm') {
    return
  } else {
    let loading = ElLoading.service({
      spinner: 'el-icon-loading',
      lock: true,
      text: '正在清零中，请稍后...',
    })
    // 发起余额清零的请求
    let res = await api.bing
      .resetAccount({
        height: window.innerHeight,
        width: window.innerWidth,
        browser: base.getBrowserInfo().browserName + '的版本是：' + base.getBrowserInfo().versionInfo,
        os: base.getOsInfo().name === 'Windows' ? base.getOsInfo().version : base.getOsInfo().name,
        acc_ids: selectedData.value.items,
      })
      .finally(() => {
        if (loading && loading.close) loading.close()
      })

    // 余额清零点击确定后的返回信息弹
    await ElMessageBox.confirm(`以下是批量清零情况:<br>${res.msg}`, '信息', {
      confirmButtonText: '收到',
      dangerouslyUseHTMLString: true,
      showCancelButton: false,
    }).finally(() => {
      refreshPageData()
    })
  }
}
</script>

<style lang="less" scoped>
i.el-icon-warning-outline {
  font-size: 18px;
  color: #909399;
  cursor: pointer;
}

.accountList p {
  margin: 10px 0;
}

.box-card :deep(.operate-row .el-button) {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
}

.box-card :deep(.operate-row .is-disabled) {
  border: 1px solid #D9D9D9;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #595959;
}

.firstRow {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .accountNum {
    :deep(.el-card__body) {
      height: 80px;
    }
  }

  :deep(.el-card) {
    border: none;
    box-shadow: none;
  }

  .el-col-16 :deep(.el-card) {
    border-radius: 0 0 0 4px;
    border-right: 1px solid #e4e7ed;
  }

  .el-col-8 :deep(.el-card) {
    border-radius: 0 4px 4px 0;
  }
}
</style>
