<template>
  <el-card class="box-card balance-card">
    <template #header>
      <el-row justify="space-between" class="card-header">
        <span class="SubHead">
          可用余额
          <el-tooltip class="item" effect="light" content="" placement="top">
            <template #content>
              可用余额：扣除掉账户余额后，还可以用于账户充值的金额；<br />
              账户余额：当前账户状态为正常的账户，账户余额的总和。
            </template>
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </span>
        <el-link type="primary" size="small" :underline=false @click="$router.push('/recharge')">
          <span class="LinkText">钱包充值</span>
        </el-link>
      </el-row>
    </template>
    <el-row v-loading="loading" class="balance-continer">
      <!-- 企业总可用余额 -->
      <el-col :span="24" class="Text" v-if="$store.getters.userInfo.manage === 1 && balanceInfo.is_balance === 1">
        <span class="title">企业总可用余额：</span>
        <span class="content">${{ filters.thousands(balanceInfo.zong_balance) }}</span>
      </el-col>
      <!-- 可用余额 -->
      <el-col :span="24" class="balance-button" :class="balanceInfo.num_type == 1 ? 'color-danger' : ''"
        style="position: relative;">
        <el-row type="flex" style="flex: 1;"
          v-if="$store.getters.userInfo.exc !== 'qbit' && $store.getters.userInfo.exc !== '' && $store.getters.userInfo.exc !== null">
          <el-tooltip trigger="hover" placement="top" effect="light" width="200">
            <span class="title">可用余额：</span>
            <template #content>
              <el-space direction="vertical" :size="10" fill style="width: 100%">
                <span>钱包余额：${{ filters.thousands(balanceInfo.balance) }}</span>
                <span :span="24">礼金余额：${{ filters.thousands(balanceInfo.bonus_balance) }}</span>
                <span :span="24">注：赠予礼金仅能用于Facebook账户充值</span>
              </el-space>
            </template>
          </el-tooltip>
          <span class="content">${{ balanceInfo.total_balance }}</span>
        </el-row>
        <el-row type="flex" style="flex: 1;" v-if="$store.getters.userInfo.exc === 'qbit'">
          <el-tooltip trigger="hover" placement="top" effect="light" width="200">
            <span class="title">可用余额：</span>
            <template #content>
              <el-space direction="vertical" :size="10" fill style="width: 100%">
                <span>钱包余额：${{ filters.thousands(balanceInfo.balance) }}</span>
              </el-space>
            </template>
          </el-tooltip>
          <span class="content">${{ balanceInfo.total_balance }}</span>
        </el-row>
        <el-row type="flex" v-if="$store.getters.userInfo.exc === '' || $store.getters.userInfo.exc === null"
          style="flex: 1">
          <span class="title">可用余额：</span>
          <span class="content">${{ filters.thousands(balanceInfo.balance) }}</span>
        </el-row>
        <el-tooltip content="点击刷新余额" effect="light" placement="top">
          <el-button class="refreshBtn" type="text" @click="refreshBalance">
            <i class="iconfont icon-ylreplace"></i>
          </el-button>
        </el-tooltip>
      </el-col>
      <!-- 账户余额 -->
      <el-col :span="24" class="Text" :class="balanceInfo.cc_type == 1 ? 'color-danger' : ''">
        <span class="title">账户余额：</span>
        <span class="content">${{ filters.thousands(balanceInfo.count) }}</span>
      </el-col>
      <!-- 授信额度 -->
      <el-col :span="24" class="Text" v-if="balanceInfo.credit_type == 2 && $store.getters.userInfo.manage === 1">
        <span class="title">授信额度：</span>
        <span class="content">${{ filters.thousands(balanceInfo.credit) }}</span>
      </el-col>
    </el-row>
  </el-card>
</template>

<script setup>
import { onMounted, ref } from "vue";
import api from "@/request/api/account";
import filters from '@/base/filters/base'
import { ElMessage } from "element-plus";

const props = defineProps({
  type: { required: true, type: String }
})
let balanceInfo = ref({
  num_type: '',
  cc_type: '',
  c_type: '',
  n_type: '',
  credit_type: '',
  count: 0,
  credit: 0,
  balance: 0,
  bonus_balance: 0,
  total_balance: 0
})

let loading = ref(false)

const getBalanceInfo = async () => {
  let map = {
    'facebook': 1,
    'tiktok': 2,
    'bing': 3
  }

  if (!map[props.type]) return null
  loading.value = true
  let res = await api.common.getBalanceInfo(map[props.type])
  balanceInfo.value = res.data
  balanceInfo.value.total_balance = filters.thousands(Math.round(((res.data.balance ? res.data.balance * 1.0 : 0) + (res.data.bonus_balance ? res.data.bonus_balance * 1.0 : 0)) * 100) / 100)
  loading.value = false
}

const refreshBalance = async () => {
  let res = await api.common.refreshBalanceInfo(1).catch(() => {
  })

  if (res && res.code == 200) {
    ElMessage.success('刷新成功')
    getBalanceInfo()
  } else {
    ElMessage.error({ message: '刷新余额失败', duration: 1000 })
  }
}
onMounted(() => {
  getBalanceInfo()
})

let getBalance = () => {
  return balanceInfo.value
}

defineExpose({ getBalance, refreshBalance })

</script>

<style lang="less" scoped>
.refreshBtn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  min-height: auto;
}

.balance-card {
  height: 180px;

  :deep(.el-card__body) {
    height: 120px;
    padding: 0 20px;
  }
}

.balance-continer {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .balance-button.el-col span {
    color: #2b82ff;
    cursor: pointer;
  }

  .el-col {
    flex: none;
    display: flex;
    align-items: center;
    padding-right: 25px;

    .title {
      display: inline-block;
      width: 112px;
      text-align: right;
      font-size: 14px;
      line-height: 24px;
    }

    .content {
      flex: 1;
      font-size: 16px;
      font-weight: 500;
      color: #262626;
      text-align: right;
      line-height: 24px;
    }
  }
}
</style>