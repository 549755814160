<template>
  <NoCompanyAttest v-if="userInfo.manage === 0" />
  <div style="position:relative;" v-else>
    <account-banner />
    <el-tabs v-model="activeName" class="listTabs">
      <el-tab-pane name="facebook" v-if="rolePermission('FacebookAccount')">
        <template #label>
          <img title="Facebook" src="@/assets/images/workSpace/facebook.svg" />
          <span id="firstStep">Facebook</span>
        </template>
        <UserAccountList v-if="activeName === 'facebook'" />
      </el-tab-pane>
      <el-tab-pane name="tiktok" v-if="rolePermission('TTAccount')">
        <template #label>
          <img title="TikTok" src="@/assets/images/workSpace/tiktok.svg" />
          <span id="firstStep">TikTok</span>
        </template>
        <TTAccountList v-if="activeName === 'tiktok'" />
      </el-tab-pane>
      <el-tab-pane name="bing" v-if="rolePermission('BingAccount')">
        <template #label>
          <img title="Microsoft" src="@/assets/images/workSpace/microsoft.svg" />
          <span id="firstStep">微软广告</span>
        </template>
        <BingAccountList v-if="activeName === 'bing'" />
      </el-tab-pane>
      <el-tab-pane name="google" v-if="rolePermission('GGAccount')">
        <template #label>
          <img title="Google" src="@/assets/images/workSpace/google.svg" />
          <span id="firstStep">Google</span>
        </template>
        <GGAccountList v-if="activeName === 'google'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import TTAccountList from '../tiktok/users/account/TTAccountList.vue'
import UserAccountList from '../users/accountList/UserAccountList.vue'
import GGAccountList from '../accountManage/google/GGAccountList.vue'
import BingAccountList from '@/views/account/manage/bing/Index'
import { getAccountPermission } from '@/request/api/business'
import NoCompanyAttest from '../base/NoCompanyAttest.vue'
import AccountBanner from '@/views/account/common/AccountBanner.vue'

export default {
  name: 'ListAccount',
  components: {
    AccountBanner,
    TTAccountList,
    UserAccountList,
    GGAccountList,
    BingAccountList,
    NoCompanyAttest
  },
  props: ['tabsType'],
  data() {
    return {
      activeName: '',
      accountPermissionPage: [],
      userInfo: this.$store.getters.userInfo
    }
  },
  methods: {
    rolePermission(node) {
      if (this.$store.getters.userInfo.manage === 1) return true
      let permission = this.accountPermissionPage || this.$store.getters.rolePermission
      let result = permission.includes(node)
      return result
    }
  },
  mounted() {
    getAccountPermission().then(res => {
      this.accountPermissionPage = res?.data
      this.$store.commit('changeRolePermission', res.data)
      if (!res?.data.includes('FacebookAccount')) {
        if (!res?.data.includes('TTAccount')) {
          if (!res?.data.includes('BingAccount')) {
            if (!res?.data.includes('GGAccount')) {
              this.activeName = 'facebook'
            } else {
              this.activeName = 'google'
            }
          } else {
            this.activeName = 'bing'
          }
        } else {
          this.activeName = 'tiktok'
        }
      } else {
        this.activeName = 'facebook'
      }
    })
    if (this.tabsType === 'tiktok') {
      this.activeName = 'tiktok'
    } else if (this.tabsType === 'bing') {
      this.activeName = 'bing'
    } else if (this.tabsType === 'google') {
      this.activeName = 'google'
    }
  },
}
</script>

<style lang="less" scoped>
.listTabs {
  &>:deep(.el-tabs__header) {
    margin-bottom: 0 !important;

    .el-tabs__nav-wrap::after {
      display: none;
    }

    .el-tabs__nav {
      display: flex;
      gap: 16px;

      .el-tabs__active-bar {
        display: none;
      }

      .el-tabs__item {
        box-sizing: border-box;
        color: #595959 !important;
        width: 180px;
        height: 48px;
        background: #FAFAFA !important;
        opacity: 1 !important;
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 6px 6px 0 0;
        padding-left: 24px;

        &.is-active {
          background: #ffffff !important;
          color: #262626 !important;
        }
      }
    }
  }
}
</style>
