<template>
  <el-dialog v-model="isDialogShow" title="多账户操作" custom-class="multiple-accounts-dialog" :close-on-click-modal="false"
    @close="$emit('close')" width="860px">
    <el-form label-position="left" :rules="rules" ref="ruleFormRef" :model="ruleForm">
      <el-form-item label="操作类型：" style="margin-bottom: 0">
        <el-radio-group v-model="handleType">
          <el-radio label="余额清零">余额清零</el-radio>
          <el-radio label="账户充值">账户充值</el-radio>
          <el-radio label="余额转移">余额转移</el-radio>
          <el-radio label="BM解绑">BM解绑</el-radio>
          <el-radio label="BM绑定">BM绑定</el-radio>
          <el-radio label="余额减款" v-if="props.creditType === 2">余额减款</el-radio>
          <el-radio label="RAII清零" v-if="userInfo.crm_id === 91122 || userInfo.crm_id === 27563">RAII清零</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-alert type="info" show-icon :closable="false" v-show="handleType == '账户充值'">
        <template #default> 注意：如账户被封，则无法充值。 </template>
      </el-alert>
      <el-alert type="info" show-icon :closable="false" v-show="handleType == '余额清零' || handleType == 'RAII清零'">
        <template #default>
          广告系列完全暂停6小时后才能够进行余额清零操作。<span
            v-if="userInfo.manage !== 1 && (userInfo.crm_id === 91122 || userInfo.crm_id === 27563)">同一账户最多只能进行被余额清零操作3次。</span>
        </template>
      </el-alert>
      <el-alert type="info" show-icon :closable="false" v-show="handleType == '余额减款'">
        <template #default>
          将您广告账户中的余额回收到钱包中。
        </template>
      </el-alert>
      <el-alert type="info" show-icon :closable="false" v-show="handleType == '余额转移'">
        <template #default>
          被封账户仅能余额转移一次，且需要将余额全部转移。
        </template>
      </el-alert>

      <div v-if="handleType == '账户充值'">
        <el-row v-for="(item, index) in ruleForm.accountRecharge" type="flex" justify="space-between">
          <el-form-item label="账户ID" :prop="`accountRecharge[${index}].id`"
            :rules="{ required: true, message: '请输入账户ID', trigger: 'blur' }" style="margin-right: 24px;">
            <el-select v-model="item.id" multiple filterable default-first-option allow-create placeholder="请输入账户ID"
              style="width: 270px;" size="small">
              <el-option v-for="item in accountOptions" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item label="充值金额" :prop="`accountRecharge[${index}].amount`"
            :rules="{ required: true, message: '请输入充值金额', trigger: 'blur' }" style="margin-right: 24px;height: 40px;">
            <el-input v-model="item.amount" size="small" clearable placeholder="请输入充值金额" />
          </el-form-item>
          <el-button v-if="index === 0" type="primary" circle @click="addAccountRecharge(index)"
            :disabled="ruleForm.accountRecharge.length === 5">
            <img src="@/assets/images/accountList/Plus.svg" alt="Plus">
          </el-button>
          <el-button v-else type="primary" circle @click="deleteAccountRecharge(index)">
            <img src="@/assets/images/accountList/Close.svg" alt="Close">
          </el-button>
        </el-row>
        <el-row type="flex" justify="end" style="width: 100%;margin-top: 8px;"
          v-if="userInfo.crm_id === 32469 || userInfo.crm_id === 27563">
          <el-form-item label="验证码" prop="code" :rules="{ required: true, message: '请输入验证码', trigger: 'blur' }"
            style="margin-right: 24px;height: 40px;">
            <el-input v-model="ruleForm.code" size="small" clearable placeholder="请输入验证码" />
          </el-form-item>
          <el-button @click="getCode" plain style="width:88px;">
            {{ ruleForm.countDownTime > 0 ? `已发送${ruleForm.countDownTime}s` : '获取验证码' }}
          </el-button>
        </el-row>
      </div>
      <div v-else-if="handleType == '余额转移'">
        <el-row>
          <el-form-item label="转移类型" class="transfer-form-item" style="margin-bottom: 20px;">
            <el-select v-model="moveType" size="small">
              <el-option label="单转多" :value="1"></el-option>
              <el-option label="多转单" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <div v-if="moveType === 1">
          <el-row type="flex" justify="space-between" style="flex-wrap: nowrap;margin-bottom: 8px;">
            <el-form-item label="转出账户ID" prop="balanceTransferSToM.id" style="margin-right: 30px;width: 294px;">
              <el-input v-model="ruleForm.balanceTransferSToM.id" placeholder="只能输入一个账户ID" size="small" clearable
                @change="getAccountBalance" />
            </el-form-item>
            <el-form-item label="账户余额" style="margin-right: 24px;">
              <el-input v-model="ruleForm.balanceTransferSToM.balanceAmount" size="small" disabled
                style="width: 138px;" />
            </el-form-item>
            <el-form-item label="转出金额">
              <el-input v-model="ruleForm.balanceTransferSToM.transferAmount" size="small" disabled
                style="width: 84px;" />
            </el-form-item>
          </el-row>
          <el-row v-for="(item, index) in ruleForm.balanceTransferSToM.children">
            <el-form-item label="转入账户ID" :prop="`balanceTransferSToM.children[${index}].id`"
              :rules="{ required: true, message: '请输入账户ID', trigger: 'blur' }" style="margin-right: 24px;width: 294px;">
              <el-input v-model="item.id" placeholder='多个账户ID请用英文逗号","隔开' size="small" clearable />
            </el-form-item>
            <el-form-item label="充值金额" :prop="`balanceTransferSToM.children[${index}].amount`"
              :rules="{ required: true, message: '请输入充值金额', trigger: 'blur' }" style="margin-right: 24px;">
              <el-input v-model="item.amount" size="small" clearable placeholder="请输入充值金额" style="width: 138px;" />
            </el-form-item>
            <el-button v-if="index === 0" type="primary" circle @click="addBalanceTransferSToM(index)"
              :disabled="ruleForm.balanceTransferSToM.children.length === 5">
              <img src="@/assets/images/accountList/Plus.svg" alt="Plus">
            </el-button>
            <el-button v-else type="primary" circle @click="deleteBalanceTransferSToM(index)">
              <img src="@/assets/images/accountList/Close.svg" alt="Close">
            </el-button>
          </el-row>
        </div>
        <div v-if="moveType === 2">
          <el-row v-for="(item, index) in ruleForm.balanceTransferMToS.children" type="flex" justify="space-between"
            style="flex-wrap: nowrap;margin-bottom: 8px;">
            <el-form-item label="转出账户ID" style="margin-right: 12px;width: 298px;"
              :prop="`balanceTransferMToS.children[${index}].id`"
              :rules="{ required: true, message: '请输入账户ID', trigger: 'blur' }">
              <el-input v-model="item.id" placeholder="只能输入一个账户ID" size="small" clearable
                @change="getAccountBalanceMToS(index)" />
            </el-form-item>
            <el-form-item label="账户余额" style="margin-right: 12px;">
              <el-input v-model="item.balanceAmount" size="small" disabled style="width: 90px" />
            </el-form-item>
            <el-form-item label="转出金额" :prop="`balanceTransferMToS.children[${index}].transferAmount`"
              :rules="{ required: true, message: '请输入转出金额', trigger: 'blur' }" style="margin-right: 12px;">
              <el-input v-model="item.transferAmount" size="small" style="width: 124px" placeholder="请输入转出金额" />
            </el-form-item>
            <el-button v-if="index === 0" type="primary" circle @click="addBalanceTransferMToS(index)" size="small"
              :disabled="ruleForm.balanceTransferMToS.children.length === 5">
              <img src="@/assets/images/accountList/Plus.svg" alt="Plus">
            </el-button>
            <el-button v-else type="primary" circle @click="deleteBalanceTransferMToS(index)">
              <img src="@/assets/images/accountList/Close.svg" alt="Close">
            </el-button>
          </el-row>
          <el-row>
            <el-form-item label="转入账户ID" prop="balanceTransferMToS.id" style="margin-right: 40px;width: 298px;">
              <el-input v-model="ruleForm.balanceTransferMToS.id" size="small" clearable placeholder="只能输入一个账户ID" />
            </el-form-item>
            <el-form-item label="充值总额">
              <el-input v-model="ruleForm.balanceTransferMToS.rechargeAmount" size="small" disabled
                style="width: 90px" />
            </el-form-item>
          </el-row>
        </div>

        <el-row type="flex" align="middle" style="margin-left: 93px;" v-if="props.creditType === 2">
          <el-checkbox v-model="agree" label="" style="margin-right: 6px"></el-checkbox>
          <span style="color: #f56c6c;font-size: 12px;">同意承担数据回滚及被封账户解封后广告投放产生的消耗，若预付余额不足够扣减解封后的消耗账单，需补打款。</span>
        </el-row>
      </div>

      <div v-else-if="handleType == '余额清零'">
        <el-row>
          <el-form-item label="账户ID" prop="zeroBalance">
            <el-select v-model="ruleForm.zeroBalance" multiple filterable default-first-option allow-create
              placeholder="请输入需要余额清零的账户ID" style="width: 420px;" size="small">
              <el-option v-for="item in accountOptions" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
        </el-row>
      </div>

      <div v-else-if="handleType == 'BM绑定'">
        <el-row>
          <el-form-item label="账户ID" prop="bmBinding.id">
            <el-select v-model="ruleForm.bmBinding.id" multiple filterable default-first-option allow-create
              placeholder="请输入账户ID" style="width: 420px;" size="small">
              <el-option v-for="item in accountOptions" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="BMID" prop="bmBinding.BMID">
            <el-input v-model="ruleForm.bmBinding.BMID" placeholder="只能输入一个BMID" size="small" clearable
              style="width: 420px; margin-left: 6px" />
          </el-form-item>
        </el-row>
      </div>

      <div v-else-if="handleType == 'BM解绑'">
        <el-row>
          <el-form-item label="账户ID" prop="bmUnbinding.id">
            <el-select v-model="ruleForm.bmUnbinding.id" multiple filterable default-first-option allow-create
              placeholder="请输入账户ID" style="width: 420px;" size="small">
              <el-option v-for="item in accountOptions" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-row>
            <el-form-item label="BMID" prop="bmUnbinding.BMID">
              <el-input v-model="ruleForm.bmUnbinding.BMID" placeholder="只能输入一个BMID" size="small" clearable
                style="width: 420px; margin-left: 6px" />
            </el-form-item>
          </el-row>
        </el-row>
      </div>

      <div v-else-if="handleType == 'RAII清零'">
        <el-row>
          <el-form-item label="账户ID" prop="zeroRaii">
            <el-select v-model="ruleForm.zeroRaii" multiple filterable default-first-option allow-create
              placeholder="请输入需要RAII清零的账户ID" style="width: 420px;" size="small">
              <el-option v-for="item in accountOptions" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
        </el-row>
      </div>

      <div v-else>
        <el-row v-for="(item, index) in ruleForm.deduction" type="flex" justify="space-between">
          <el-form-item label="账户ID" style="margin-right: 24px" :prop="`deduction[${index}].id`"
            :rules="{ required: true, message: '请输入账户ID', trigger: 'blur' }">
            <el-select v-model="item.id" multiple filterable default-first-option allow-create placeholder="请输入账户ID"
              style="width: 270px;" size="small">
              <el-option v-for="item in accountOptions" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item label="减款金额" :prop="`deduction[${index}].amount`"
            :rules="{ required: true, message: '请输入减款金额', trigger: 'blur' }" style="margin-right: 24px">
            <el-input v-model="item.amount" size="small" clearable placeholder="请输入减款金额" />
          </el-form-item>
          <el-button v-if="index === 0" type="primary" circle @click="addDeduction(index)" size="small"
            :disabled="ruleForm.deduction.length === 5">
            <img src="@/assets/images/accountList/Plus.svg" alt="Plus">
          </el-button>
          <el-button v-else type="primary" circle @click="deleteDeduction(index)">
            <img src="@/assets/images/accountList/Close.svg" alt="Close">
          </el-button>
        </el-row>
      </div>

      <el-row type="flex" align="middle" style="margin-left: 65px;"
        v-show="handleType === '余额清零' || handleType === 'RAII清零'">
        <el-checkbox v-model="agree" label="" style="margin-right: 6px"></el-checkbox>
        <span style="color: #f56c6c;font-size: 12px;"
          v-if="props.creditType === 1">同意承担被封账户解封后广告投放产生的消耗，若预付余额不足够扣减解封后的消耗账单，需补打款。</span>
        <span style="color: #f56c6c;font-size: 12px;"
          v-if="props.creditType === 2">同意承担数据回滚及被封账户解封后广告投放产生的消耗，若预付余额不足够扣减解封后的消耗账单，需补打款。</span>
      </el-row>

      <el-row type="flex" align="middle" style="margin-left: 65px;"
        v-show="handleType === '余额减款' && props.creditType === 2">
        <el-checkbox v-model="agree" label="" style="margin-right: 6px"></el-checkbox>
        <span style="color: #f56c6c;font-size: 12px;">同意承担数据回滚及被封账户解封后广告投放产生的消耗，若预付余额不足够扣减解封后的消耗账单，需补打款。</span>
      </el-row>

    </el-form>
    <template #footer>
      <el-button size="small" @click="$emit('close')">取消</el-button>
      <el-button size="small" type="primary" @click="handleConfirm(ruleFormRef)" :disabled="isButtonDisabled || !agree"
        style="margin-left: 16px;"
        v-if="handleType === '余额清零' || handleType === 'RAII清零' || (props.creditType === 2 && (handleType === '余额转移' || handleType === '余额减款'))">确认</el-button>
      <el-button size="small" type="primary" @click="handleConfirm(ruleFormRef)" :disabled="isButtonDisabled"
        style="margin-left: 16px;" v-else>确认</el-button>
    </template>
  </el-dialog>

  <!-- 账户充值对话框 -->
  <el-dialog v-model="isRechargeDialogShow" width="500px" :close-on-click-modal="false" top="25vh"
    custom-class="multiple-accounts-recharge-dialog" title="账户充值信息核对">
    <div class="recharge-dialog-container">
      <div class="recharge-dialog-header">
        <div>充值账户</div>
        <div>充值金额</div>
      </div>
      <div class="account-list">
        <div v-for="(item, index) in accountRechargeChecked" :key="index" class="account-item">
          <div>{{ item.id }}</div>
          <div>
            $ {{ item.amount }}
          </div>
        </div>
      </div>
    </div>
    <div class="recharge-dialog-footer">
      <div>
        <div>账户总数：{{ accountRechargeChecked.length }}</div>
      </div>
      <div>
        <div>充值总金额：<span class="recharge-amount">$ {{ accountRechargeAmount }}</span></div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="isRechargeDialogShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleAccountRecharge">确 认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import api from '@/request/api/account'
import { getPhoneCode, checkPhoneCode } from '@/request/api/common'
import { ref, reactive, computed, getCurrentInstance, onMounted } from 'vue'
import { ElMessage, ElLoading } from 'element-plus'

const { proxy } = getCurrentInstance()

const props = defineProps({
  // 客户类型，1：预付，2：后付
  creditType: {
    type: Number,
  },
  operateType: {
    type: String,
  },
  accountIds: {
    type: Array,
  },
  // 分页信息
  queryInfo: {
    type: Object,
  },
  // 筛选条件
  accountForm: {
    type: Object,
  },
})
const userInfo = ref(proxy.$store.getters.userInfo)

// 账户充值核对展示
const accountRechargeChecked = computed(() => {
  const flattenedData = data => data.reduce((acc, curr) => {
    // 遍历每个对象中的 id 数组，并将其转换为单独的对象，并添加到结果数组中
    curr.id.forEach((id) => {
      acc.push({ id: String(id), amount: curr.amount });
    });
    return acc;
  }, []);

  const mergeAndSumById = arr => {
    const merged = {};
    arr.forEach(item => {
      const id = item.id;
      const amount = parseFloat(item.amount);
      if (merged[id]) {
        merged[id].amount += amount;
      } else {
        merged[id] = { id, amount };
      }
    });
    return Object.values(merged);
  }

  const arr = JSON.parse(JSON.stringify(ruleForm.value.accountRecharge))
  return mergeAndSumById(flattenedData(arr))
})

// 账户充值总金额
const accountRechargeAmount = computed(() => {
  return ruleForm.value.accountRecharge.reduce((total, item) => {
    return total + parseFloat(item.amount) * item.id.length
  }, 0)
})
// 余额转移单转多转出金额计算
const transferAmountComputed = computed(() => {
  return ruleForm.value.balanceTransferSToM.children.reduce((total, item) => {
    return isNaN(parseFloat(item.amount)) ? total : total + parseFloat(item.amount)
  }, 0)
})
// 余额转移多转单充值总额计算
const rechargeAmountComputed = computed(() => {
  return ruleForm.value.balanceTransferMToS.children.reduce((total, item) => {
    return isNaN(parseFloat(item.transferAmount)) ? total : total + parseFloat(item.transferAmount)
  }, 0)
})

const agree = ref(true) // 是否同意余额清零协议
const isButtonDisabled = ref(false) // 确认按钮是否禁用
const isDialogShow = ref(true) // 是否显示弹窗
const isRechargeDialogShow = ref(false) // 是否显示账户充值弹窗
const handleType = ref('余额清零') // 多账户操作类型
const moveType = ref(1) // 余额转移类型 1: 单转多 2: 多转单
const accountOptions = ref([]) // 账户ID下拉列表
const ruleForm = ref({
  // 账户充值
  accountRecharge: [{ id: [], amount: '' }],
  // 余额转移单转多
  balanceTransferSToM: {
    id: '',
    balanceAmount: '',
    transferAmount: transferAmountComputed,
    children: [{ id: '', amount: '' }],
  },
  // 余额转移多转单
  balanceTransferMToS: {
    id: '',
    rechargeAmount: rechargeAmountComputed,
    children: [{ id: '', balanceAmount: '', transferAmount: '' }],
  },
  // 余额清零
  zeroBalance: [],
  // BM绑定
  bmBinding: {
    id: [],
    BMID: '',
  },
  // BM解绑
  bmUnbinding: {
    id: [],
    BMID: '',
  },
  // 余额减款
  deduction: [{
    id: [],
    amount: '',
  }],
  // RAII清零
  zeroRaii: [],
  // 账户充值验证码（针对卓达主体）
  code: '',
  countDownTime: 0,
})
const ruleFormRef = ref()
// 表单验证规则
const rules = reactive({
  zeroBalance: [{ required: true, message: '请输入账户ID', trigger: 'blur' }],
  zeroRaii: [{ required: true, message: '请输入账户ID', trigger: 'blur' }],
  'balanceTransferSToM.id': [{ required: true, message: '请输入账户ID', trigger: 'blur' }],
  'balanceTransferMToS.id': [{ required: true, message: '请输入账户ID', trigger: 'blur' }],
  'bmBinding.id': [{ required: true, message: '请输入账户ID', trigger: 'blur' }],
  'bmBinding.BMID': [{ required: true, message: '请输入BMID', trigger: 'blur' }],
  'bmUnbinding.id': [{ required: true, message: '请输入账户ID', trigger: 'blur' }],
  'bmUnbinding.BMID': [{ required: true, message: '请输入BMID', trigger: 'blur' }],
})

// 确认操作
const handleConfirm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (!valid) return
    switch (handleType.value) {
      case '账户充值':
        if (userInfo.value.crm_id === 32469 || userInfo.value.crm_id === 27563) {
          checkPhoneCode(ruleForm.value.code).then(res => {
            if (res.code === 200) {
              isRechargeDialogShow.value = true
            } else {
              ElMessage.error(res.msg)
            }
          })
        } else {
          isRechargeDialogShow.value = true
        }
        break

      case '余额转移':
        handleBalanceTransfer()
        break

      case '余额清零':
        handleZeroBalance()
        break

      case 'BM绑定':
        handleBmBinding()
        break

      case 'BM解绑':
        handleBmUnbinding()
        break

      case '余额减款':
        handleDeduction()
        break

      case 'RAII清零':
        handleZeroRaii()
        break

      default:
        break
    }
  })
}

// 账户操作后刷新保留查询条件和筛选条件
const setSessionStorage = () => {
  sessionStorage.setItem('accountListQueryInfo', JSON.stringify(props.queryInfo))
  sessionStorage.setItem('accountListAccountForm', JSON.stringify(props.accountForm))
}

// 添加账户充值列表
const addAccountRecharge = (index) => {
  ruleForm.value.accountRecharge.push({ id: [], amount: '' })
}
// 删除账户充值列表
const deleteAccountRecharge = (index) => {
  ruleForm.value.accountRecharge.splice(index, 1)
}
// 添加余额转移单转多列表
const addBalanceTransferSToM = (index) => {
  ruleForm.value.balanceTransferSToM.children.push({ id: '', amount: '' })
}
// 删除余额转移单转多列表
const deleteBalanceTransferSToM = (index) => {
  ruleForm.value.balanceTransferSToM.children.splice(index, 1)
}
// 添加余额转移多转单列表
const addBalanceTransferMToS = (index) => {
  ruleForm.value.balanceTransferMToS.children.push({ id: '', balanceAmount: '', transferAmount: '' })
}
// 删除余额转移多转单列表
const deleteBalanceTransferMToS = (index) => {
  ruleForm.value.balanceTransferMToS.children.splice(index, 1)
}
// 添加余额减款列表
const addDeduction = (index) => {
  ruleForm.value.deduction.push({ id: [], amount: '' })
}
// 删除余额减款列表
const deleteDeduction = (index) => {
  ruleForm.value.deduction.splice(index, 1)
}
// 单转多查询账户余额
const getAccountBalance = async () => {
  let res = await api.facebook.getAccountBalance({
    account_id: parseInt(ruleForm.value.balanceTransferSToM.id),
    type: 2,
  })
  ruleForm.value.balanceTransferSToM.balanceAmount = res.account_balance
}
// 多转单查询账户余额
const getAccountBalanceMToS = async (index) => {
  let res = await api.facebook.getAccountBalance({
    account_id: parseInt(ruleForm.value.balanceTransferMToS.children[index].id),
    type: 2,
  })
  ruleForm.value.balanceTransferMToS.children[index].balanceAmount = res.account_balance
}
// 账户充值请求
const requestAccountRecharge = async () => {
  const loading = ElLoading.service({
    spinner: 'el-icon-loading',
    lock: true,
    text: '充值中，请稍后...',
  })
  try {
    let res = await api.facebook.accountOperate({
      recharge_account_ids: ruleForm.value.accountRecharge.map((item) => {
        return {
          account_id: item.id.join(','),
          money: parseFloat(item.amount),
        }
      }),
      type: 3,
    })
    if (res.type === 2) return ElMessage.success(res.msg)
    // 充值点击确定后的返回信息弹框
    proxy.$alert(`以下是批量充值情况:<br>${res.msg}`, '信息', {
      confirmButtonText: '收到',
      dangerouslyUseHTMLString: true,
      callback: (action) => {
        setSessionStorage()
        window.location.reload()
      },
    })
  } catch (error) {
    // 处理错误
  } finally {
    ruleFormRef.value.resetFields()
    isDialogShow.value = false
    loading.close()
    isButtonDisabled.value = false;
  }
}
// 账户充值
const handleAccountRecharge = async () => {
  isButtonDisabled.value = true;
  const rechargeHistory = await api.common.getRechargeHistory({
    type: 2,
    media_type: 1,
    recharge_accounts: JSON.stringify(ruleForm.value.accountRecharge.map((item) => {
      return {
        account_id: item.id.join(','),
        money: parseFloat(item.amount),
      }
    }))
  })
  if (rechargeHistory.exa === 1) {
    // 24小时有同金额充值记录
    const confirmRecharge = await proxy.$confirm(
      `${rechargeHistory.account_id}账户24小时内有同金额充值记录,确定要继续充值吗?`,
      '提示',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    ).catch((err) => err)
    if (confirmRecharge !== 'confirm') {
      isButtonDisabled.value = false;
      return
    }
    return requestAccountRecharge()
  }
  requestAccountRecharge()
}
// 余额转移
const handleBalanceTransfer = async () => {
  isButtonDisabled.value = true;
  let params =
    moveType.value === 1
      ? {
        transfer_out_account: [{ account_id: ruleForm.value.balanceTransferSToM.id, amount: transferAmountComputed.value }],
        transfer_in_account: ruleForm.value.balanceTransferSToM.children.map((item) => {
          return {
            account_id: item.id,
            amount: parseFloat(item.amount),
          }
        }),
        type: 1,
        media_type: 1,
      }
      : {
        transfer_out_account: ruleForm.value.balanceTransferMToS.children.map((item) => {
          return {
            account_id: item.id,
            amount: parseFloat(item.transferAmount),
          }
        }),
        transfer_in_account: [{ account_id: ruleForm.value.balanceTransferMToS.id, amount: rechargeAmountComputed.value }],
        type: 2,
        media_type: 1,
      }
  const loading = ElLoading.service({
    spinner: 'el-icon-loading',
    lock: true,
    text: '转移中，请稍后...',
  })
  try {
    let res = await api.facebook.handleAccountTransfer(params)
    if (res.type === 2) return ElMessage.success(res.msg)
    // 余额转移点击确定后的返回信息弹框
    proxy.$alert(`以下是余额转移情况:<br>${res.msg}`, '信息', {
      confirmButtonText: '收到',
      dangerouslyUseHTMLString: true,
      callback: (action) => {
        setSessionStorage()
        window.location.reload()
      },
    })
  } catch (error) {
    // 处理错误
  } finally {
    loading.close()
    ruleFormRef.value.resetFields()
    isDialogShow.value = false
    isButtonDisabled.value = false;
  }
}
// 余额清零
const handleZeroBalance = async () => {
  isButtonDisabled.value = true;
  // 确认是否有未暂停的广告系列
  let pauseAccount = await api.facebook.getPauseAccount({ acc_ids: JSON.stringify(ruleForm.value.zeroBalance) })
  if (pauseAccount.pause === 0) {
    const loading = ElLoading.service({
      spinner: 'el-icon-loading',
      lock: true,
      text: '清零中，请稍后...',
    })
    try {
      let res = await api.facebook.accountOperate({
        account_ids: ruleForm.value.zeroBalance.join(','),
        type: 4,
      })
      if (res.type === 2) return ElMessage.success(res.msg)
      // 余额清零点击确定后的返回信息弹
      proxy.$alert(`以下是批量清零情况:<br>${res.msg}`, '信息', {
        confirmButtonText: '收到',
        dangerouslyUseHTMLString: true,
        callback: (action) => {
          setSessionStorage()
          window.location.reload()
        },
      })
    } catch (error) {
      // 处理错误
    } finally {
      loading.close()
      ruleFormRef.value.resetFields()
      isDialogShow.value = false
      isButtonDisabled.value = false;
    }
  } else {
    const confirmResult = await proxy.$confirm(
      `账户ID：${pauseAccount.account_id}的广告系列未暂停，点击确定将立即暂停广告系列。广告系列暂停6小时后，账户将自动清零。若等待清零时对账户进行操作需继续等待6小时，确保广告系列完全暂停6小时后进行清零。`,
      '提示',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    ).catch((err) => err)
    if (confirmResult !== 'confirm') return
    const loading = ElLoading.service({
      spinner: 'el-icon-loading',
      lock: true,
      text: '清零中，请稍后...',
    })
    try {
      let res = await api.facebook.accountOperate({
        account_ids: ruleForm.value.zeroBalance.join(','),
        type: 4,
      })
      if (res.type === 2) return ElMessage.success(res.msg)
      // 余额清零点击确定后的返回信息弹
      proxy.$alert(`以下是批量清零情况:<br>${res.msg}`, '信息', {
        confirmButtonText: '收到',
        dangerouslyUseHTMLString: true,
        callback: (action) => {
          setSessionStorage()
          window.location.reload()
        },
      })
    } catch (error) {
      // 处理错误
    } finally {
      loading.close()
      ruleFormRef.value.resetFields()
      isDialogShow.value = false
      isButtonDisabled.value = false;
    }
  }
}

// RAII清零
const handleZeroRaii = async () => {
  isButtonDisabled.value = true;
  // 确认是否有未暂停的广告系列
  let pauseAccount = await api.facebook.getPauseAccount({ acc_ids: JSON.stringify(ruleForm.value.zeroRaii) })
  if (pauseAccount.pause === 0) {
    const loading = ElLoading.service({
      spinner: 'el-icon-loading',
      lock: true,
      text: '清零中，请稍后...',
    })
    try {
      let res = await api.facebook.accountOperate({
        account_ids: ruleForm.value.zeroRaii.join(','),
        type: 6,
      })
      if (res.type === 2) return ElMessage.success(res.msg)
      // 余额清零点击确定后的返回信息弹
      proxy.$alert(`以下是批量清零情况:<br>${res.msg}`, '信息', {
        confirmButtonText: '收到',
        dangerouslyUseHTMLString: true,
        callback: (action) => {
          setSessionStorage()
          window.location.reload()
        },
      })
    } catch (error) {
      // 处理错误
    } finally {
      loading.close()
      ruleFormRef.value.resetFields()
      isDialogShow.value = false
      isButtonDisabled.value = false;
    }
  } else {
    const confirmResult = await proxy.$confirm(
      `账户ID：${pauseAccount.account_id}的广告系列未暂停，点击确定将立即暂停广告系列。广告系列暂停6小时后，账户将自动清零。若等待清零时对账户进行操作需继续等待6小时，确保广告系列完全暂停6小时后进行清零。`,
      '提示',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    ).catch((err) => err)
    if (confirmResult !== 'confirm') return
    const loading = ElLoading.service({
      spinner: 'el-icon-loading',
      lock: true,
      text: '清零中，请稍后...',
    })
    try {
      let res = await api.facebook.accountOperate({
        account_ids: ruleForm.value.zeroRaii.join(','),
        type: 6,
      })
      if (res.type === 2) return ElMessage.success(res.msg)
      // 余额清零点击确定后的返回信息弹
      proxy.$alert(`以下是批量清零情况:<br>${res.msg}`, '信息', {
        confirmButtonText: '收到',
        dangerouslyUseHTMLString: true,
        callback: (action) => {
          setSessionStorage()
          window.location.reload()
        },
      })
    } catch (error) {
      // 处理错误
    } finally {
      loading.close()
      ruleFormRef.value.resetFields()
      isDialogShow.value = false
      isButtonDisabled.value = false;
    }
  }
}
// BM绑定
const handleBmBinding = async () => {
  isButtonDisabled.value = true;
  const loading = ElLoading.service({
    spinner: 'el-icon-loading',
    lock: true,
    text: '绑定中，请稍后...',
  })
  try {
    let res = await api.facebook.accountOperate({
      account_ids: ruleForm.value.bmBinding.id.join(','),
      bm_id: ruleForm.value.bmBinding.BMID,
      type: 1,
    })
    if (res.type === 2) return ElMessage.success(res.msg)
    // BM绑定点击确定后的返回信息弹框
    proxy.$alert(`以下是BM绑定情况:<br>${res.msg}`, '信息', {
      confirmButtonText: '收到',
      dangerouslyUseHTMLString: true,
      callback: (action) => {
        setSessionStorage()
        window.location.reload()
      },
    })
  } catch (error) {
    // 处理错误
  } finally {
    loading.close()
    ruleFormRef.value.resetFields()
    isDialogShow.value = false
    isButtonDisabled.value = false;
  }
}
// BM解绑
const handleBmUnbinding = async () => {
  isButtonDisabled.value = true;
  const loading = ElLoading.service({
    spinner: 'el-icon-loading',
    lock: true,
    text: '解绑中，请稍后...',
  })
  try {
    let res = await api.facebook.accountOperate({
      account_ids: ruleForm.value.bmUnbinding.id.join(','),
      bm_id: ruleForm.value.bmUnbinding.BMID,
      type: 2,
    })
    if (res.type === 2) return ElMessage.success(res.msg)
    // BM解绑点击确定后的返回信息弹框
    proxy.$alert(`以下是BM解绑情况:<br>${res.msg}`, '信息', {
      confirmButtonText: '收到',
      dangerouslyUseHTMLString: true,
      callback: (action) => {
        setSessionStorage()
        window.location.reload()
      },
    })
  } catch (error) {
    // 处理错误
  } finally {
    loading.close()
    ruleFormRef.value.resetFields()
    isDialogShow.value = false
    isButtonDisabled.value = false;
  }
}
// 余额减款
const handleDeduction = async () => {
  isButtonDisabled.value = true;
  const loading = ElLoading.service({
    spinner: 'el-icon-loading',
    lock: true,
    text: '减款中，请稍后...',
  })
  try {
    let res = await api.facebook.accountOperate({
      recharge_account_ids: ruleForm.value.deduction.map((item) => {
        return {
          account_id: item.id.join(','),
          money: parseFloat(item.amount),
        }
      }),
      type: 5,
    })
    if (res.type === 2) return ElMessage.success(res.msg)
    // 余额减款点击确定后的返回信息弹框
    proxy.$alert(`以下是余额减款情况:<br>${res.msg}`, '信息', {
      confirmButtonText: '收到',
      dangerouslyUseHTMLString: true,
      callback: (action) => {
        setSessionStorage()
        window.location.reload()
      },
    })
  } catch (error) {
    // 处理错误
  } finally {
    loading.close()
    ruleFormRef.value.resetFields()
    isDialogShow.value = false
    isButtonDisabled.value = false;
  }
}

// 账户充值验证获取验证码（针对卓达主体）
const getCode = async () => {
  // 如果当前没有计时
  if (!ruleForm.value.countDownTime) {
    // 启动倒计时
    ruleForm.value.countDownTime = 60
    const clock = window.setInterval(() => {
      ruleForm.value.countDownTime--
      if (ruleForm.value.countDownTime <= 0) {
        clearInterval(clock)
      }
    }, 1000)

    const phone = userInfo.value.crm_id === 32469 ? '19875592526' : '17638581137'
    await getPhoneCode(phone).then(res => {
      if (res.code === '1') {
        ElMessage.success({
          message: `验证码成功发送到企业超管手机号：${userInfo.value.crm_id === 32469 ? '198xxxx2526' : '176xxxx1137'}`,
          duration: 5000,
        })
      }
    })
  }
}

onMounted(() => {
  api.facebook.getAllAccountList().then((res) => {
    accountOptions.value = res.data.map(item => item.toString())
  })
  if (!props.operateType) return
  handleType.value = props.operateType
  switch (props.operateType) {
    case '账户充值':
      ruleForm.value.accountRecharge[0].id = props.accountIds
      break

    case '余额转移':
      moveType.value = 2
      ruleForm.value.balanceTransferMToS.children = props.accountIds.map(item => {
        return { id: item, balanceAmount: '', transferAmount: '' }
      })
      for (let i = 0; i < props.accountIds.length; i++) {
        getAccountBalanceMToS(i)
      }
      break

    case '余额清零':
      ruleForm.value.zeroBalance = props.accountIds
      break

    case 'BM绑定':
      ruleForm.value.bmBinding.id = props.accountIds
      break

    case 'BM解绑':
      ruleForm.value.bmUnbinding.id = props.accountIds
      break

    case '余额减款':
      ruleForm.value.deduction[0].id = props.accountIds
      break

    case 'RAII清零':
      ruleForm.value.zeroRaii = props.accountIds
      break

    default:
      break
  }
})
</script>

<style lang="less">
.multiple-accounts-dialog,
.multiple-accounts-recharge-dialog {
  .el-dialog__body {
    padding: 15px 24px 25px 24px;
  }

  .el-dialog__footer {
    padding: 0 24px 24px 24px !important;
  }
}
</style>
<style lang="less" scoped>
.multiple-accounts-dialog {
  .el-select {
    width: 100%;
  }
}

.recharge-dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  height: 38px;
  background: #F5F7FA;
  border-bottom: 1px solid #D9D9D9;

  div {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #8C8C8C;
    line-height: 38px;
  }

  &>div:nth-child(2) {
    width: 156px;
    text-align: left;
  }
}

.recharge-dialog-footer {
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #595959;
  line-height: 22px;
  margin-top: 25px;

  &>div:first-child {
    display: flex;
    justify-content: flex-end;

    div {
      width: 150px;
      text-align: left;
    }
  }

  &>div:nth-child(2) {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;

  }

  .recharge-amount {
    font-size: 20px;
    font-family: D-DIN, D-DIN;
    color: #F56C6C;
  }
}

.recharge-dialog-container {
  border-radius: 2px;
  border: 1px solid #D9D9D9;

  .account-list {
    max-height: 194px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #C1C1C1;
    }

    div,
    span {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #595959;
    }

    .account-item {
      height: 38px;
      padding-left: 18px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #D9D9D9;

      &>div:first-child {
        width: 276px;
        text-align: left;
      }

      &>div:nth-child(2) {
        flex: 1;
        text-align: left;
      }
    }

    .account-item:last-child {
      border-bottom: none;
    }
  }
}

:deep(.el-form-item) {
  margin-bottom: 10px;
}

.el-form .el-button {
  height: 32px;
  min-height: 32px;
  width: 32px;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-alert--info.is-light {
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff !important;
  margin-bottom: 18px;
}

:deep(.el-alert .el-alert__description) {
  margin: 0;
  font-family: PingFang SC, PingFang SC;
  color: #595959 !important;
}

:deep(.el-alert__title.is-bold) {
  font-size: 16px;
  font-weight: 400;
  color: #51575a;
}

:deep(.transfer-form-item .el-form-item__content) {
  width: 200px;
}

:deep(.transfer-form-item .el-form-item__label) {
  margin-left: 24px;
}

:deep(.el-select .el-select__tags .el-tag) {
  width: 160px;
}

:deep(.el-select .el-select__tags .el-select__tags-text) {
  width: 135px;
}


:deep(.el-select .el-select__tags) {
  max-height: 120px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #C1C1C1;
  }

  .el-select__tags-text {
    font-size: 14px;
    overflow: hidden;
  }
}
</style>
