<template>
  <div class="account-form">
    <el-form ref="accountFormRef" :inline="true" :model="accountForm">
      <el-row class="first-row">
        <el-form-item label="广告账户" prop="adIdValue">
          <el-input placeholder="请输入广告账户ID或账户名称" size="small" clearable v-model.trim="accountForm.adIdValue"
            style="width:220px"></el-input>
        </el-form-item>
        <el-form-item label="账户状态" prop="state">
          <el-select v-model="accountForm.state" placeholder="请选择状态" size="small" clearable>
            <el-option label="正常" value="1"></el-option>
            <el-option label="被封" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="BMID" prop="BMID">
          <el-input placeholder="请输入BMID" size="small" clearable v-model.trim="accountForm.BMID"></el-input>
        </el-form-item>
        <el-form-item label="营业执照" prop="company">
          <el-input placeholder="请输入营业执照" size="small" clearable v-model="accountForm.company"></el-input>
        </el-form-item>
        <el-form-item label="自定义标签" prop="label_id">
          <el-select v-model="accountForm.label_id" placeholder="请选择标签" size="small" clearable>
            <el-option v-for="item in [{ id: 0, name: '无标签账户' }, ...labelSelectOptions]" :key="item.id"
              :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-row>
      <el-row class="downLoadRow">
        <el-form-item label="多广告账户ID精确查询" prop="account_ids">
          <el-input placeholder='请输入广告账户ID，多个账户ID用英文逗号","隔开' size="small" clearable
            v-model.trim="accountForm.account_ids"></el-input>
        </el-form-item>
        <el-form-item label="多营业执照精确查询" prop="chinese_legal_entity_names">
          <el-input placeholder='请输入营业执照，多个营业执照用英文逗号","隔开' size="small" clearable
            v-model.trim="accountForm.chinese_legal_entity_names"></el-input>
        </el-form-item>
      </el-row>
      <el-row class="last-row">
        <el-form-item label="消耗状态" prop="spent_status">
          <el-select v-model="accountForm.spent_status" placeholder="有/无" size="small" clearable>
            <el-option label="有" :value="1"></el-option>
            <el-option label="无" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择日期" prop="timeRange" style="margin-right: 10px;">
          <el-date-picker v-model="accountForm.timeRange" type="daterange" size="small" start-placeholder="开始日期"
            end-placeholder="结束日期" value-format="X" :disabled-date="disabledDate"
            @calendar-change="calendarChange"></el-date-picker>
        </el-form-item>
        <span class="Text" style="margin-top: 12px;margin-right: 10px;">已选择{{ SelectNumber }}个</span>
        <el-form-item style="margin-right: 0;">
          <el-button type="primary" @click="queryFormBtn" size="small">查询</el-button>
          <el-button @click="resetFormBtn" size="small">重置</el-button>
          <el-tooltip class="item" effect="light" content="请至少选择一个广告账户" placement="top"
            v-if="(userInfo.manage === 1 || userInfo.manage === 3) && SelectNumber === 0">
            <el-button disabled size="small" style="color: #8C8C8C;">批量分配广告账户权限</el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="请至少选择一个广告账户" placement="top"
            v-if="(userInfo.manage === 1 || rolePermission('FacebookAccountLabelEdit')) && SelectNumber === 0">
            <el-button disabled size="small" style="color: #8C8C8C;">批量添加标签</el-button>
          </el-tooltip>
          <el-button type="primary" id="downLoad" @click="batchDivideAccount" size="small"
            v-if="(userInfo.manage === 1 || userInfo.manage === 3) && SelectNumber !== 0">批量分配广告账户权限
          </el-button>
          <el-button type="primary" id="downLoad" @click="batchAddLabel" size="small"
            v-if="(userInfo.manage === 1 || rolePermission('FacebookAccountLabelEdit')) && SelectNumber !== 0">批量添加标签
          </el-button>
          <el-button type="primary" id="downLoad" @click="downLoadExpend" size="small">导出账户消耗</el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </div>

  <el-alert title="提示" description="" type="info" class="alert-blue">
    <template #default>
      如需要绑定像素请在问题反馈提交
      <router-link to="/feedback" class="articleLink">需求工单</router-link>
      ，我们会在工作时间及时为您处理。格式：像素ID：xxx 分享到BMID：xxx或账户ID：xxx。
      <div style="height: 4px;"></div>
      若您的账户被封，请查看文章：<a class="articleLink" :href="`${base.url}/help/1108?type=1`"
        target="_blank">Facebook广告账户被封原因及处理方式</a>
      寻求帮助
      <div style="height: 4px;"></div>
      若您需要对多个账户以及被封账户同时进行批量操作,请点击<span class="articleLink" @click="HandleAccounts">多账户批量操作</span>
    </template>
  </el-alert>

  <el-alert type="info" class="alert-yellow guidance" :closable="false" v-if="guidance.show"
    @click="goFBGuidance(2, guidance.url)">
    <template #title>
      {{ guidance.title ? guidance.title : '广告投放建议' }}
      <el-button plain round v-if="guidance.help" @click="goFBGuidance(4, guidance.help)" type="warning" size="mini">
        指导手册
      </el-button>
    </template>
    <template #default>
      <el-row class="guidance-text" v-html="guidance.text"></el-row>
      <img src="@/assets/images/accountList/alert.png" alt="" />
    </template>
  </el-alert>

  <el-table header-row-class-name="account-table-head" ref="allAccountRef" :data="allAccountList"
    :row-key="getAccountId" style="width: 100%" :header-cell-style="{
      background: 'var(--el-table-row-hover-background-color)',
    }" @selection-change="handleSelectionChange" @sort-change="handleSortChange" v-loading="tableLoading">
    <el-table-column label-class-name="account-name" type="selection" :reserve-selection="true" :selectable="blockedRow"
      width="55" />
    <el-table-column property="" label="账户名称" min-width="200">
      <template #default="{ row }">
        {{ row.name }} <i class="el-icon-edit-outline Text" @click="editName(row)"></i>
      </template>
    </el-table-column>
    <el-table-column property="account_id" label="账户ID" min-width="160" />
    <el-table-column property="" label="营业执照" min-width="200">
      <template #default="{ row }">
        {{ row.chinese_legal_entity_name }}
      </template>
    </el-table-column>
    <el-table-column label="账户状态" min-width="140">
      <template #default="{ row }">
        <el-tag type="success" v-if="row.account_status == 1">正常</el-tag>
        <el-tag type="danger" v-else>被封</el-tag>
      </template>
    </el-table-column>
    <el-table-column property="spent_x" label="消耗" min-width="100"
      v-if="rolePermission('FacebookAccountBalanceAndSpent')">
      <template #default="{ row }"> ${{ $filters.thousands(row.spent_x) }}</template>
    </el-table-column>
    <el-table-column property="account_balance" label="账户余额" sortable min-width="160"
      v-if="rolePermission('FacebookAccountBalanceAndSpent')">
      <template #default="{ row }">
        <span> ${{ $filters.thousands(row.account_balance) }}</span>
        <el-tooltip class="item" effect="light" content="" placement="top">
          <template #content> 余额已转移，可用余额为0</template>
          <i class="el-icon-warning listWarning" v-show="row.account_status != 1 && row.transfer_status == 2"
            style=""></i>
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column property="bm_ids" label="绑定的BM" min-width="300" show-overflow-tooltip />
    <el-table-column :width="getColumnWidth('label')" min-width="130">
      <template #header>
        <div>
          自定义标签
          <el-tooltip class="item" effect="light" content="" placement="top">
            <template #content>
              <div>
                每个账户最多关联5个标签
              </div>
            </template>
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
      </template>
      <template #default="{ row }">
        <div class="label-popover">
          <el-tag v-for="item in row.label" :key="item.id" type="info" size="small" @close="deleteLabel(row, item.id)"
            :closable="userInfo.manage === 1 || rolePermission('FacebookAccountLabelEdit')">
            {{ item.name }}
          </el-tag>
          <el-popover :width="320" :visible="row.account_id === currentAccountId" :ref="`popover-${row.account_id}`"
            placement="bottom" :show-arrow="false" popper-class="fb-all-account-label-popover"
            v-if="row.label.length < 5 && (userInfo.manage === 1 || rolePermission('FacebookAccountLabelEdit'))">
            <div class="container">
              <div class="title">自定义标签</div>
              <el-select v-model="labelSelect" multiple filterable default-first-option allow-create
                placeholder="请选择或新增标签" :multiple-limit="5" size="small" style="width: 100%;" @change="filterLabel">
                <el-option v-for="item in labelSelectOptions" :key="item.id" :label="item.name" :value="item.name" />
              </el-select>
              <div class="footer">
                <el-button size="small" @click="labelCancelBtn">取消</el-button>
                <el-button size="small" type="primary" @click="labelSumBtn(row)">确认</el-button>
              </div>
            </div>
            <template #reference>
              <div class="add-label-button" @click="addLabel(row)">
                <el-icon style="margin-right:4px;color:#2b82ff;">
                  <Plus />
                </el-icon>
                添加标签
              </div>
            </template>
          </el-popover>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="140" fixed="right">
      <template #default="{ row }">
        <el-button type="text" @click="divideAccount(row)"
          style="font-family: PingFangSC, PingFang SC;max-width: 110px;text-wrap: wrap;text-align: left;"
          v-if="userInfo.manage === 1 || userInfo.manage === 3">管理权限
        </el-button>
        <el-tooltip content="点击刷新账户余额" effect="light" placement="top">
          <el-button type="text" @click="refreshBalance(row.account_id)">
            <i class="iconfont icon-ylreplace"></i>
          </el-button>
        </el-tooltip>
      </template>
    </el-table-column>
    <template #empty>
      <img src="@/assets/empty.gif" alt="" style="width: 400px; height: 400px" />
    </template>
  </el-table>

  <el-row>
    <el-col :span="15" style="padding-top: 15px" id="secondRow">
      <span class="Text" style="margin-right: 15px">已选择 <strong style="color: #2b82ff;font-size: 16px;">{{
        SelectNumber
      }}</strong> 个</span>
      <el-button size="mini" @click="accountOperate('余额清零')" :type="SelectNumber == 0 ? '' : 'primary'"
        :disabled="SelectNumber === 0">余额清零
      </el-button>
      <el-button size="mini" @click="accountOperate('账户充值')" :type="SelectNumber == 0 ? '' : 'primary'"
        :disabled="SelectNumber === 0">账户充值
      </el-button>
      <el-button size="mini" @click="accountOperate('余额转移')" :type="SelectNumber == 0 ? '' : 'primary'"
        :disabled="SelectNumber === 0">余额转移
      </el-button>
      <el-button size="mini" @click="accountOperate('BM解绑')" :type="SelectNumber == 0 ? '' : 'primary'"
        :disabled="SelectNumber === 0">BM解绑
      </el-button>
      <el-button size="mini" @click="accountOperate('BM绑定')" :type="SelectNumber == 0 ? '' : 'primary'"
        :disabled="SelectNumber === 0">BM绑定
      </el-button>
      <el-button size="mini" @click="accountOperate('余额减款')" :type="SelectNumber == 0 ? '' : 'primary'"
        v-if="credit_typ === 2" :disabled="SelectNumber === 0">余额减款
      </el-button>
      <el-button size="mini" @click="accountOperate('RAII清零')" :type="SelectNumber == 0 ? '' : 'primary'"
        v-if="userInfo.crm_id === 91122 || userInfo.crm_id === 27563" :disabled="SelectNumber === 0">RAII清零
      </el-button>
    </el-col>
    <el-col :span="9" style="text-align: right">
      <!-- 分页器 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[2, 5, 10, 20, 50]" :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-col>
  </el-row>

  <!-- 广告账户分配对话框 -->
  <el-dialog v-model="divideDialogVisible" width="30%" custom-class="divide-dialog" :close-on-click-modal="false">
    <template #title>
      <div v-if="dialogType === 'divide'">
        广告账户权限管理
      </div>
      <div v-else>
        广告账户权限批量分配
        <el-tooltip class="item" effect="light" content="" placement="top">
          <template #content>
            <div>
              广告账户默认由管理员全部可见，企业成员只能查看或操作分配到所属用户组的广告账户；
            </div>
            <div>
              批量分配的广告账户仅增加可见人员，不会删除或减少已设置的企业人员;
            </div>
            <div>
              若需删改可见成员请点击广告账户列表中的管理权限按钮。
            </div>
          </template>
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
    </template>
    <!-- 内容主体区域 -->
    <el-form label-position="right" label-width="90px">
      <el-form-item label="广告账户ID">
        <div v-if="dialogType === 'divide'" class="select-account">{{ singleSelectAccount.name }} {{
          '(' +
          singleSelectAccount.account_id + ')'
        }}
        </div>
        <div class="divide-dialog-container" v-else>
          <div class="account-list">
            <span v-for="item in checkTableList" :key="item.account_id">
              {{ item.name }} {{ '(' + item.account_id + ')' }}
            </span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="可见成员">
        <div class="divide-dialog-container">
          <div class="user-search">
            <el-input v-model="searchKeyWord" size="small" placeholder="搜索用户组和企业成员" @keyup.enter.native="handleSearch">
              <template #prefix>
                <img src="@/assets/images/business/search.svg" alt=""
                  style="vertical-align: middle;margin-left: 4px;cursor: pointer;" @click="handleSearch">
              </template>
            </el-input>
          </div>
          <div class="user-list">
            <el-checkbox v-model="isCheckAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全部企业成员
            </el-checkbox>
            <el-checkbox-group v-model="checkedUserGroup" @change="handleCheckedUserChange">
              <el-checkbox v-for="item in userGroupList" :key="item.id" :label="item.id">
                <el-tooltip effect="dark" content="用户组" placement="top">
                  <img src="@/assets/images/business/userGroup.svg" alt=""
                    style="vertical-align: middle;margin-right: 3px;"></el-tooltip>
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
            <el-checkbox-group v-model="checkedUser" @change="handleCheckedUserChange">
              <el-checkbox v-for="item in userList" :key="item.id" :label="item.id">
                <el-tooltip effect="dark" content="用户" placement="top">
                  <img src="@/assets/images/business/user.svg" alt=""
                    style="vertical-align: middle;margin-right: 3px;"></el-tooltip>
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="devideFormClose">取 消</el-button>
        <el-button size="small" type="primary" @click="devideSumBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 批量添加标签对话框 -->
  <el-dialog v-model="addLabelDialogVisible" width="30%" custom-class="divide-dialog" :close-on-click-modal="false"
    @close="singleLabel = ''">
    <template #title>
      <div>
        批量添加标签
        <el-tooltip class="item" effect="light" content="" placement="top">
          <template #content>
            <div>
              广告账户默认由管理员全部可见，企业成员只能查看或操作分配到所属用户组的广告账户；
            </div>
            <div>
              批量添加标签仅增加标签，不会删除或减少已添加的标签;
            </div>
            <div>
              若需删减标签请点击广告账户列表中的标签进行删除。
            </div>
          </template>
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
    </template>
    <!-- 内容主体区域 -->
    <el-form label-position="right" label-width="90px">
      <el-form-item label="广告账户ID">
        <div class="divide-dialog-container">
          <div class="account-list">
            <span v-for="item in checkTableList" :key="item.account_id">
              {{ item.name }} {{ '(' + item.account_id + ')' }}
            </span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="自定义标签">
        <el-select v-model="singleLabel" filterable default-first-option allow-create placeholder="请选择或新增标签"
          class="add-label-select" clearable @change="filterBatchLabel">
          <el-option v-for="item in labelSelectOptions" :key="item.id" :label="item.name" :value="item.name" />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="addLabelDialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="batchAddLabelSumBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 账户充值对话框 -->
  <el-dialog v-model="rechargeDialogVisible" title="账户充值" width="30%">
    <!-- 内容主体区域 -->
    <el-form ref="rechargeFormRef" :model="rechargeForm" :rules="rechargeFormRules" label-width="60px"
      @submit.native.prevent>
      <el-form-item label="金额" prop="money">
        <el-input size="small" placeholder="请输入充值金额$" clearable v-model="rechargeForm.money"
          @keyup.enter.native="rechargeSumBtn"></el-input>
        <span class="Text">可用余额：{{ balance }}美元</span>
      </el-form-item>
      <el-form-item label="预览">
        已选择 <strong class="color-primary">{{ SelectNumber }}</strong> 个账户，预计扣款
        <strong class="color-primary">{{ SelectNumber * rechargeForm.money }}</strong> 美元
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="rechargeFormClose">取 消</el-button>
        <el-button size="small" type="primary" @click="rechargeSumBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- BMID解绑对话框 -->
  <el-dialog v-model="BMUnbindDialogVisible" title="BM解绑" width="30%">
    <!-- 内容主体区域 -->
    <el-form ref="BMUnbindFormRef" :model="BMUnbindForm" :rules="BMUnbindFormRules" label-width="60px"
      @submit.native.prevent>
      <el-form-item label="BMID" prop="unbindId">
        <el-input size="small" placeholder="请输入解绑的BMID" clearable v-model.trim="BMUnbindForm.unbindId"
          @keyup.enter.native="BMUnbindBtn"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="BMUnbindFormClose">取 消</el-button>
        <el-button size="small" type="primary" @click="BMUnbindBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- BMID绑定对话框 -->
  <el-dialog v-model="BMBindDialogVisible" title="BM绑定" width="30%">
    <!-- 内容主体区域 -->
    <el-form ref="BMBindFormRef" :model="BMBindForm" :rules="BMBindFormRules" label-width="60px" @submit.native.prevent>
      <el-form-item label="BMID" prop="bindId">
        <el-input size="small" placeholder="请输入绑定的BMID" clearable v-model.trim="BMBindForm.bindId"
          @keyup.enter.native="BMBindBtn"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="BMBindFormClose">取 消</el-button>
        <el-button size="small" type="primary" @click="BMBindBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 余额减款对话框 -->
  <el-dialog v-model="deductionDialogVisible" title="余额减款" width="30%">
    <!-- 内容主体区域 -->
    <el-form ref="deductionFormRef" :model="deductionForm" :rules="deductionFormRules" label-width="60px"
      @submit.native.prevent>
      <el-form-item label="金额" prop="money">
        <el-input size="small" placeholder="请输入减款金额$" clearable v-model="deductionForm.money"
          @keyup.enter.native="deductionSumBtn"></el-input>
        <el-form-item label="预览">
          已选择 <strong class="color-primary">{{ SelectNumber }}</strong> 个账户，预计减款 <strong class="color-primary">{{
            SelectNumber * deductionForm.money
          }}</strong> 美元到钱包余额
        </el-form-item>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="deductionFormClose">取 消</el-button>
        <el-button size="small" type="primary" @click="deductionSumBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 下载弹窗 -->
  <el-dialog v-model="downLoadDialogVisible" title="下载消耗" width="790px" custom-class="downLoadExpend">
    <!-- 内容主体区域 -->
    <el-form ref="downLoadFormRef" label-width="100px">
      <el-form-item label="选择时间" class="timeRange">
        <el-date-picker v-model="expendTimeRange" type="daterange" size="small" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="X" :disabled-date="disabledDate"
          @calendar-change="calendarChange"></el-date-picker>
      </el-form-item>
      <el-form-item label="选择账户">
        <el-radio v-model="accountType" label="1" size="small">所有账户</el-radio>
        <el-radio v-model="accountType" label="2" size="small">有消耗的账户</el-radio>
      </el-form-item>
      <el-form-item label="选择字段" class="downLoadForm">
        <el-transfer v-model="expendValue" style="text-align: left; display: inline-block" :left-default-checked="[
          'name',
          'account_id',
          'chinese_legal_entity_name',
          'account_status',
          'spent_x',
          'account_balance',
          'insert_time',
          'bm_ids',
          'label'
        ]" :right-default-checked="[]" :titles="['可选字段', '已选字段']" :button-texts="['移除', '添加']" :format="{
          noChecked: '${total}',
          hasChecked: '${checked}/${total}',
        }" :data="expendData">
        </el-transfer>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="downLoadFormClose">取 消</el-button>
        <el-button size="small" type="primary" @click="downLoadFormBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 修改账户名称对话框 -->
  <el-dialog v-model="editNameDialogVisible" title="修改账户名" width="30%">
    <!-- 内容主体区域 -->
    <el-form ref="editNameFormRef" :model="editNameForm" :rules="editNameFormRules" label-width="100px">
      <el-form-item label="原账户名称">
        <el-input size="small" clearable :placeholder="oldName" disabled></el-input>
      </el-form-item>
      <el-form-item label="新账户名称" prop="accName">
        <el-input size="small" placeholder="请输入新的账户名称" clearable v-model="editNameForm.accName"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="editNameDialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="editNameBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 操作多账户 -->
  <HandleAccountsDialog v-if="isDialogShow" @close="accountsDialogClose" :credit-type="credit_typ"
    :operate-type="operateType" :account-ids="newArrayIds" :query-info="queryInfo" :account-form="accountForm" />
</template>

<script>
import api from '@/request/api/account'
import { ElLoading } from 'element-plus'
import HandleAccountsDialog from './HandleAccountsDialog.vue'
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import base from '../../../request/base'
import filter from '@/base/filters/base'
import { getAccountRule, editAccountRule, getAccountPermission } from '@/request/api/business'
import { Plus } from '@element-plus/icons';
import filters from '@/base/filters/base'

export default {
  name: 'AllAccount',
  components: {
    HandleAccountsDialog,
  },
  data() {
    //浏览器高度
    var height = window.innerHeight
    //浏览器宽度
    var width = window.innerWidth
    //浏览器
    // 浏览器检查结果
    let browserInfo = getBrowserInfo()
    var browser = browserInfo.browserName + '的版本是：' + browserInfo.versionInfo

    function getBrowserInfo() {
      let sysObj = {}
      let browserInfo = navigator.userAgent.toLowerCase()
      let regular = /(msie|firefox|chrome|opera|version).*?([\d.]+)/
      let vsMsg = browserInfo.match(regular)
      sysObj.browserName = vsMsg[1].replace(/version/, "'safari")
      sysObj.versionInfo = vsMsg[2]
      return sysObj
    }

    // 获取操作系统信息
    function getOsInfo() {
      var userAgent = navigator.userAgent.toLowerCase()
      var name = 'Unknown'
      var version = 'Unknown'
      if (userAgent.indexOf('win') > -1) {
        name = 'Windows'
        if (userAgent.indexOf('windows nt 5.0') > -1) {
          version = 'Windows 2000'
        } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
          version = 'Windows XP'
        } else if (userAgent.indexOf('windows nt 6.0') > -1) {
          version = 'Windows Vista'
        } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
          version = 'Windows 7'
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
          version = 'Windows 8'
        } else if (userAgent.indexOf('windows nt 6.3') > -1) {
          version = 'Windows 8.1'
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
          version = 'Windows 10'
        } else {
          version = 'Unknown'
        }
      } else if (userAgent.indexOf('iphone') > -1) {
        name = 'Iphone'
      } else if (userAgent.indexOf('mac') > -1) {
        name = 'Mac'
      } else if (
        userAgent.indexOf('x11') > -1 ||
        userAgent.indexOf('unix') > -1 ||
        userAgent.indexOf('sunname') > -1 ||
        userAgent.indexOf('bsd') > -1
      ) {
        name = 'Unix'
      } else if (userAgent.indexOf('linux') > -1) {
        if (userAgent.indexOf('android') > -1) {
          name = 'Android'
        } else {
          name = 'Linux'
        }
      } else {
        name = 'Unknown'
      }
      return { name, version }
    }

    var os = getOsInfo().name
    if (os == 'Windows') {
      os = getOsInfo().version
    }

    return {
      base: base,
      driverList: null,
      intro: '',
      userInfo: '',
      // 表单查询
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5,
      },
      // 客户端信息
      width: width,
      height: height,
      browser: browser,
      os: os,
      searchKeyWord: '',
      singleLabel: '', // 批量添加标签选中的标签
      labelSelect: [], // 选中账号的标签
      labelSelectOptions: [],  // 标签选项
      currentAccountId: '',
      addLabelDialogVisible: false, // 是否显示批量添加标签对话框
      divideDialogVisible: false, // 是否显示广告账户分配对话框
      dialogType: 'divide',
      singleSelectAccount: {},
      userGroupList: [], // 用户组列表
      userList: [], // 用户列表
      isCheckAll: false, // 用户组是否全选
      isIndeterminate: false, // 用户组是否半选
      checkedUserGroup: [], // 选中的用户组
      checkedUser: [], // 选中的用户
      rechargeDialogVisible: false, // 是否显示充值对话框
      rechargeForm: {  // 添加用户的充值表单数据
        money: '',
      },
      // 添加充值表单的验证规则对象
      rechargeFormRules: {
        money: [
          { required: true, message: '请输入正确的金额', trigger: 'blur' },
          {
            pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
            message: '充值金额错误，请重新输入',
            trigger: 'blur',
          },
        ],
      },
      // 减款后付对话框
      deductionDialogVisible: false,
      // 添加用户的减款表单数据
      deductionForm: {
        money: '',
      },
      // 添加减款表单的验证规则对象
      deductionFormRules: {
        money: [
          { required: true, message: '请输入正确的金额', trigger: 'blur' },
          {
            pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
            message: '减款金额错误，请重新输入',
            trigger: 'blur',
          },
        ],
      },
      // 解绑对话框
      BMUnbindDialogVisible: false,
      // 添加用户的解绑表单数据
      BMUnbindForm: {
        unbindId: '',
      },
      // 添加解绑表单的验证规则对象
      BMUnbindFormRules: {
        unbindId: [
          { required: true, message: '请输入BMID', trigger: 'blur' },
          {
            min: 15,
            max: 18,
            message: 'BMID位数为15-18位',
            trigger: 'blur',
          },
        ],
      },
      // 绑定对话框
      BMBindDialogVisible: false,
      // 添加用户的绑定表单数据
      BMBindForm: {
        bindId: '',
      },
      // 添加绑定表单的验证规则对象
      BMBindFormRules: {
        bindId: [
          { required: true, message: '请输入BMID', trigger: 'blur' },
          {
            min: 15,
            max: 18,
            message: 'BMID位数为15-18位',
            trigger: 'blur',
          },
        ],
      },
      // 查询用户信息的表单数据
      accountForm: {
        adIdValue: '',
        state: '',
        BMID: '',
        label_id: '',
        timeRange: '',
        company: '',
        balance: null,
        spent_status: null,
        account_ids: '',
        chinese_legal_entity_names: '',
      },
      allAccountList: [], // 所有账户列表
      total: 0,
      formInline: {
        normal: '',
        blocked: '',
      },
      operateType: null,
      newArrayIds: [], // 选中的账户id
      SelectNumber: 0,
      // 是否预后付 1是预付 2后
      credit_typ: '',
      // 1代表渠道
      qudao: '',
      // 账户充值是否可点击标识
      balance: '',
      // 选中的list
      checkTableList: [],
      // 最多选中的条数
      checkNumber: 5,
      // 导出消耗弹数据
      downLoadDialogVisible: false,
      accountType: '1',
      expendTimeRange: '',
      expendValue: ['account_id', 'spent_x'],
      expendData: [
        {
          key: 'name',
          label: '账户名称',
        },
        {
          key: 'account_id',
          label: '账户ID',
          disabled: true,
        },
        {
          key: 'chinese_legal_entity_name',
          label: '营业执照',
        },
        {
          key: 'account_status',
          label: '账户状态',
        },
        {
          key: 'spent_x',
          label: '消耗',
          disabled: true,
        },
        {
          key: 'account_balance',
          label: '账户余额',
        },
        {
          key: 'insert_time',
          label: '下户时间',
        },
        {
          key: 'bm_ids',
          label: '绑定的BM',
        },
        {
          key: 'label',
          label: '自定义标签',
        },
      ],
      editNameDialogVisible: false,
      editNameForm: {
        accName: '',
      },
      editNameFormRules: {
        accName: [
          { required: true, message: '请输入新的账户名称', trigger: 'blur' },
          {
            pattern: /^[\u4E00-\u9FA5A-Za-z0-9-.| _+&（）()]+$/,
            message: '新名称仅可包含中文、英文、数字、空格、中英文括号和“-”，“.”，“|”，“_”，“+”，“&”等',
            trigger: 'blur',
          },
        ],
      },
      accId: '',
      oldName: '',
      // 代投客户不能充值
      fbServiceMode: '',
      startDate: null,
      isDialogShow: false,
      accountPermissionPage: [], // 用户权限
      // fb优化指引
      guidance: {
        show: false,
        text: '',
        url: ''
      },
      filters: filters,
      tableLoading: false,
    }
  },
  created() {
    if (this.accountForm.timeRange == '') {
      this.accountForm.timeRange = this.getSevenDays()
    }
    if (this.expendTimeRange == '') {
      this.expendTimeRange = this.getSevenDays()
    }
  },
  mounted() {
    if (sessionStorage.getItem('accountListQueryInfo')) {
      this.queryInfo = JSON.parse(sessionStorage.getItem('accountListQueryInfo'))
      this.accountForm = JSON.parse(sessionStorage.getItem('accountListAccountForm'))
    }
    // 获取localStorage中的用户信息
    this.userInfo = this.$store.getters.userInfo
    this.getAllAccountList()
    this.getAdData()
    sessionStorage.removeItem('accountListQueryInfo')
    getAccountPermission().then(res => {
      this.accountPermissionPage = res.data
      this.$store.commit('changeRolePermission', res.data)
    })
    this.checkFBGuidance()
  },
  beforeUnmount() {
    if (this.driverList != null) {
      this.driverList.reset()
    }
  },
  methods: {
    // 默认显示近7天时间范围
    getSevenDays() {
      const start = Math.round((new Date().getTime() - 8.64e7 * 7) / 1000)
      const end = Math.round((new Date().getTime() - 8.64e7) / 1000)
      return [start, end]
    },
    // 查询按钮
    async queryFormBtn() {
      this.$refs.accountFormRef.validate((valid) => {
        if (!valid) return
        this.getAllAccountList()
      })
    },
    // table需设置row-key
    getAccountId(account) {
      return account.account_id
    },
    // 重置按钮
    resetFormBtn() {
      this.$refs.accountFormRef.resetFields()
      this.getAllAccountList()
    },
    // 获取广告数据
    async getAdData() {
      const res = await this.$http.post('cloudfb/ad_data', {
        token: this.$store.getters.tokenInfo
      })
      if (res.data.code === 200) {
        this.labelSelectOptions = res.data.data.label
      }
    },
    // 获取列表数据
    async getAllAccountList() {
      this.tableLoading = true
      const { data: res } = await this.$http.get('cloudfb/ad_list', {
        params: {
          page: this.queryInfo.page,
          pagesize: this.queryInfo.pagesize,
          bm_id: this.accountForm.BMID,
          is_label: this.accountForm.label_id === 0 ? 2 : 1,
          label_id: this.accountForm.label_id === 0 ? null : this.accountForm.label_id,
          account_id: this.accountForm.adIdValue,
          account_status: this.accountForm.state,
          timeRange: this.accountForm.timeRange,
          chinese_legal_entity_name: this.accountForm.company,
          balance: this.accountForm.balance,
          account_ids: this.accountForm.account_ids,
          chinese_legal_entity_names: this.accountForm.chinese_legal_entity_names,
          spent_status: this.accountForm.spent_status,
        },
      }).finally(() => {
        this.tableLoading = false
      })
      if (res.code == 100) {
        this.$message.error(`${res.msg}`)
        this.$router.push('/clientInfo')
      }
      if (res.code == 300) {
        this.$router.push('/login')
      }
      this.allAccountList = res.advertising
      this.total = res.data.total
      this.credit_typ = res.data.credit_type
      this.qudao = res.data.qudao
      this.balance = res.data.balance
      this.fbServiceMode = res.data.fb_service_mode
      this.intro = res.data.intro
      this.listGuide()
    },
    listGuide() {
      if (this.intro != 1) return
      // cosmic客户不显示引导
      if (this.$store.getters.userInfo.crm_id === 91122 || this.$store.getters.userInfo.crm_id === 27563) return
      this.$nextTick(() => {
        this.driverList = new Driver({
          allowClose: false,
          opacity: 0.1,
          onReset: async () => {
            this.intro = 0
            await this.$http.post('user/set_intro', {
              type: 'accountList',
            })
            this.getAllAccountList()
          },
        })

        this.driverList.defineSteps([
          {
            element: '.account-table-head th.account-name',
            popover: {
              title: '账户列表(1/2)',
              description:
                '<i class="el-icon-info" style="font-size: 16px; color: #2b82ff"></i>选中账户之后，点击列表下方按钮可以进行批量充值、清零等',
              position: 'right',
              nextBtnText: '下一步',
              closeBtnText: '',
            },
          },
          {
            element: '#secondRow',
            popover: {
              title: '账户列表(2/2)',
              description: '点击下方按钮可以进行批量充值、清零等。',
              position: 'top-right',
              nextBtnText: '',
              prevBtnText: '上一步',
              closeBtnText: '',
              doneBtnText: '完成',
            },
          },
        ])
        this.driverList.start()
      })
    },
    // 展示条数改变触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getAllAccountList()
    },
    // 展示点击分页触发的函数
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getAllAccountList()
    },
    handleSelectionChange(selection) {
      this.SelectNumber = selection.length
      this.checkTableList = selection
      const newArrIds = []
      selection.forEach((item) => {
        newArrIds.push(item.account_id)
      })
      this.newArrayIds = newArrIds
    },
    async clearRemainder() {
      if (this.SelectNumber == 0) {
        return this.$message({
          showClose: true,
          message: '请选择广告账户',
          type: 'warning',
        })
      }
      if (this.credit_typ == 1) {
        var msg = `你确定要将所选的${this.SelectNumber}个账户清零吗？请先确保该广告账户下所有广告已暂停5小时以上。`
      } else {
        var msg = `你确定要将所选的${this.SelectNumber}个账户清零吗？`
      }
      const confirmResult = await this.$confirm(`${msg}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).catch((err) => err)

      if (confirmResult !== 'confirm') {
        return
      } else {
        let loading = ElLoading.service({
          spinner: 'el-icon-loading',
          lock: true,
          text: '正在清零中，请稍后...',
        })
        // 发起余额清零的请求
        const { data: res } = await this.$http
          .post('cloudfb/resets', {
            height: this.height,
            width: this.width,
            browser: this.browser,
            os: this.os,
            acc_ids: this.newArrayIds,
          })
          .finally(() => {
            if (loading && loading.close) loading.close()
          })

        if (res.code == 300) {
          this.$router.push('/login')
        }
        if (res.code == 100) {
          return this.$message.error(`${res.msg}`)
        }

        // 触发更新余额模块的金额
        this.$bus.emit('getUserBalance')

        // 余额清零点击确定后的返回信息弹
        this.$alert(`以下是批量清零情况:<br>${res.msg}`, '信息', {
          confirmButtonText: '收到',
          dangerouslyUseHTMLString: true,
          callback: (action) => {
            // this.$message({
            //   type: 'info',
            //   message: `action: ${action}`
            // })
            this.$refs.allAccountRef.clearSelection()
            // 清零后重新请求刷新列表
            this.getAllAccountList()
          },
        })
      }
    },

    // 账户充值
    async recharge() {
      if (this.SelectNumber == 0) {
        return this.$message({
          showClose: true,
          message: '请选择广告账户',
          type: 'warning',
        })
      }

      if (this.balance <= 0) {
        return this.$message.error('您的可用余额不足，无法充值广告账户')
      }

      this.rechargeDialogVisible = true
    },
    rechargeFormClose() {
      this.rechargeDialogVisible = false
      this.$refs.rechargeFormRef.resetFields()
    },
    // 充值请求
    async handleRecharge() {
      let loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        text: '正在充值中，请稍后...',
      })
      const { data: res } = await this.$http
        .post('cloudfb/recharge_account', {
          height: this.height,
          width: this.width,
          browser: this.browser,
          os: this.os,
          money: this.rechargeForm.money,
          acc_ids: this.newArrayIds,
        })
        .finally(() => {
          if (loading && loading.close) loading.close()
        })

      // 触发更新余额模块的金额
      this.$bus.emit('getUserBalance')

      // 充值点击确定后的返回信息弹框
      this.$alert(`以下是批量充值情况:<br>${res.msg}`, '信息', {
        confirmButtonText: '收到',
        dangerouslyUseHTMLString: true,
        callback: (action) => {
          this.$refs.allAccountRef.clearSelection()
          // 清零后重新请求刷新列表
          this.getAllAccountList()
        },
      })
    },
    // 充值对话框确定按钮
    rechargeSumBtn() {
      this.$refs.rechargeFormRef.validate(async (valid) => {
        if (!valid) return
        this.rechargeDialogVisible = false
        // 再次确认是否充值
        const confirmResult = await this.$confirm(
          `确定向所选的${this.SelectNumber}个广告账户中,每个都充值${this.rechargeForm.money}美金吗?`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        ).catch((err) => err)

        if (confirmResult !== 'confirm') return
        const rechargeHistory = await api.common.getRechargeHistory({
          acc_ids: this.newArrayIds,
          type: 1,
          money: this.rechargeForm.money,
          media_type: 1,
        })
        if (rechargeHistory.exa === 1) {
          // 24小时有同金额充值记录
          const confirmRecharge = await this.$confirm(
            `${rechargeHistory.account_id}账户24小时内有同金额充值记录,确定要继续充值吗?`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
          ).catch((err) => err)
          if (confirmRecharge !== 'confirm') return
          return this.handleRecharge()
        }
        this.handleRecharge()
      })
    },

    // 余额减款
    async deduction() {
      if (this.SelectNumber == 0) {
        return this.$message({
          showClose: true,
          message: '请选择广告账户',
          type: 'warning',
        })
      }

      if (this.balance <= 0) {
        return this.$message.error('您的可用余额不足，无法减款到钱包')
      }

      this.deductionDialogVisible = true
    },
    deductionFormClose() {
      this.deductionDialogVisible = false
      this.$refs.deductionFormRef.resetFields()
    },
    // 减款对话框确定按钮
    deductionSumBtn() {
      this.$refs.deductionFormRef.validate(async (valid) => {
        if (!valid) return
        this.deductionDialogVisible = false
        // 再次确认是否减款
        const confirmResult = await this.$confirm(
          `确定向所选的${this.SelectNumber}个广告账户中,每个都减款${this.deductionForm.money}美金到钱包吗?`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        ).catch((err) => err)

        if (confirmResult !== 'confirm') {
          return
        } else {
          let loading = ElLoading.service({
            spinner: 'el-icon-loading',
            lock: true,
            text: '正在减款中，请稍后...',
          })

          // 发起减款的请求
          const res = await api.facebook.handleAccountDeduction({
            money: this.deductionForm.money,
            acc_ids: this.newArrayIds
          }).finally(() => {
            if (loading && loading.close) loading.close()
          })

          // 触发更新余额模块的金额
          this.$bus.emit('getUserBalance')

          // 减款点击确定后的返回信息弹框
          this.$alert(`以下是批量减款情况:<br>${res.msg}`, '信息', {
            confirmButtonText: '收到',
            dangerouslyUseHTMLString: true,
            callback: (action) => {
              this.$refs.allAccountRef.clearSelection()
              // 清零后重新请求刷新列表
              this.getAllAccountList()
            },
          })
        }
      })
    },

    // BM解绑
    async BMUnbind() {
      if (this.SelectNumber == 0) {
        this.BMUnbindDialogVisible = false
        return this.$message({
          showClose: true,
          message: '请选择广告账户',
          type: 'warning',
        })
      }
      this.BMUnbindDialogVisible = true
    },
    BMUnbindFormClose() {
      this.BMUnbindDialogVisible = false
      this.$refs.BMUnbindFormRef.resetFields()
    },
    // 解绑对话框确定按钮
    BMUnbindBtn() {
      this.$refs.BMUnbindFormRef.validate(async (valid) => {
        if (!valid) return
        this.BMUnbindDialogVisible = false
        // 再次确认是否解绑
        const confirmResult = await this.$confirm(
          `你确定要将所选的${this.SelectNumber}个广告账户,与${this.BMUnbindForm.unbindId}解绑吗？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        ).catch((err) => err)

        if (confirmResult !== 'confirm') {
          return
        } else {
          let loading = ElLoading.service({
            spinner: 'el-icon-loading',
            lock: true,
            text: '正在解绑中，请稍后...',
          })
          // 发起解绑的请求
          const { data: res } = await this.$http
            .post('cloudfb/set_bm', {
              height: this.height,
              width: this.width,
              browser: this.browser,
              os: this.os,
              acc_ids: this.newArrayIds,
              bm_id: this.BMUnbindForm.unbindId,
              type: 2,
            })
            .finally(() => {
              if (loading && loading.close) loading.close()
            })

          if (res.code == 300) {
            this.$router.push('/login')
          }
          if (res.code == 100) {
            return this.$message.error(`${res.msg}`)
          }
          // 解绑点击确定后的返回信息弹框
          this.$alert(`以下是批量解绑情况:<br>${res.msg}`, '信息', {
            confirmButtonText: '收到',
            dangerouslyUseHTMLString: true,
            callback: (action) => {
              this.$refs.allAccountRef.clearSelection()
              // 解绑后重新请求刷新列表
              this.getAllAccountList()
            },
          })
        }
      })
    },

    // BM绑定
    async BMBind() {
      if (this.SelectNumber == 0) {
        return this.$message({
          showClose: true,
          message: '请选择广告账户',
          type: 'warning',
        })
      }
      this.BMBindDialogVisible = true
    },
    BMBindFormClose() {
      this.BMBindDialogVisible = false
      this.$refs.BMBindFormRef.resetFields()
    },
    BMBindBtn() {
      this.$refs.BMBindFormRef.validate(async (valid) => {
        if (!valid) return
        this.BMBindDialogVisible = false
        // 再次确认是否解绑
        const confirmResult = await this.$confirm(
          `你确定要将所选的${this.SelectNumber}个广告账户,与${this.BMBindForm.bindId}绑定吗？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        ).catch((err) => err)

        if (confirmResult !== 'confirm') {
          return
        } else {
          let loading = ElLoading.service({
            spinner: 'el-icon-loading',
            lock: true,
            text: '正在绑定中，请稍后...',
          })
          // 发起绑定的请求
          const { data: res } = await this.$http
            .post('cloudfb/set_bm', {
              height: this.height,
              width: this.width,
              browser: this.browser,
              os: this.os,
              acc_ids: this.newArrayIds,
              bm_id: this.BMBindForm.bindId,
              type: 1,
            })
            .finally(() => {
              if (loading && loading.close) loading.close()
            })

          if (res.code == 300) {
            this.$router.push('/login')
          }
          if (res.code == 100) {
            return this.$message.error(`${res.msg}`)
          }
          // 绑定点击确定后的返回信息弹框
          this.$alert(`以下是批量绑定情况:<br>${res.msg}`, '信息', {
            confirmButtonText: '收到',
            dangerouslyUseHTMLString: true,
            callback: (action) => {
              this.$refs.allAccountRef.clearSelection()
              // 绑定后重新请求刷新列表
              this.getAllAccountList()
            },
          })
        }
      })
    },
    blockedRow(row, index) {
      if (row.account_status != 1) {
        return true
      } else {
        return true
      }
    },
    // 下载消耗数据
    downLoadExpend() {
      this.downLoadDialogVisible = true
    },
    downLoadFormBtn() {
      if (this.expendValue == '') {
        return this.$message.warning('请选择字段')
      }

      var expendValue = this.expendValue.join(',')
      var expendTimeRange = this.expendTimeRange.join(',')

      const loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        text: '下载中，请稍后...',
      })

      this.$http({
        // 用axios发送post请求
        method: 'post',
        url: 'cloudfb/ad_export', // 请求地址
        data: {
          token: this.userInfo.token,
          timeRange: expendTimeRange,
          field: expendValue,
          account: this.accountType,
        },
        responseType: 'blob', // 表明返回服务器返回的数据类型
      })
        .then((res) => {
          // 处理返回的文件流
          const content = res.data
          const blob = new Blob([content])
          const fileName = '账户消耗.xls'
          if ('download' in document.createElement('a')) {
            // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
        .finally(() => {
          loading.close()
        })

      this.downLoadDialogVisible = false
    },
    downLoadFormClose() {
      this.downLoadDialogVisible = false
    },
    async editName(row) {
      if (row.account_status != 1) {
        return
      }
      this.editNameDialogVisible = true
      this.accId = row.account_id
      this.oldName = row.name
    },
    editNameBtn() {
      this.$refs.editNameFormRef.validate(async (valid) => {
        if (!valid) return
        this.editNameDialogVisible = false
        const confirmResult = await this.$confirm('你确定要更改账户名称吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).catch((err) => err)

        if (confirmResult !== 'confirm') {
          return
        } else {
          // 发起改名的请求
          const { data: res } = await this.$http.post('cloudfb/edit_account_name', {
            name: this.editNameForm.accName,
            id: this.accId,
          })

          if (res.code == 300) {
            this.$router.push('/login')
          }
          if (res.code == 100) {
            return this.$message.error(`${res.msg}`)
          }
          this.$message.success('修改成功')
          window.location.reload()
        }
      })
    },
    async refreshBalance(id) {
      const { data: res } = await this.$http.post('cloudfb/refresh_balance', {
        type: 2,
        account_id: id,
      })
      if (res.code == 100) {
        return this.$message.error('刷新失败')
      }
      this.$message.success('刷新成功')
      this.getAllAccountList()
    },
    calendarChange(dates) {
      // 记录选择的开始日期，方便后面根据开始日期限定结束日期
      if (dates !== null && dates.length > 0) {
        this.startDate = dates[0]
      }
    },
    // 日期选择器禁止选择的条件
    disabledDate(time) {
      // 8.64e7 等于 3600 * 1000 * 24
      if (this.startDate !== null) {
        return (
          time.getTime() > Date.now() - 8.64e7 ||
          time.getTime() < 1640966400 * 1000 ||
          time.getTime() > this.startDate.getTime() + 180 * 8.64e7 ||
          time.getTime() < this.startDate.getTime() - 180 * 8.64e7
        )
      } else {
        return time.getTime() < 1640966400 * 1000 || time.getTime() > Date.now() - 8.64e7
      }
    },
    handleSortChange({ column, order }) {
      this.accountForm.balance = filter.transSortOrder(order)
      this.getAllAccountList()
    },
    HandleAccounts() {
      this.isDialogShow = true
    },
    accountOperate(type) {
      if (type === '余额转移' && this.newArrayIds.length > 5) {
        this.$message.warning('最多只能对5个账户进行余额转移操作')
        return
      }
      this.operateType = type
      this.isDialogShow = true
    },
    accountsDialogClose() {
      this.isDialogShow = false
      this.operateType = null
    },
    handleSearch() {
      if (this.dialogType === 'batchDivide') {
        getAccountRule({ keyword: this.searchKeyWord }).then(res => {
          this.userGroupList = res.data.filter(item => item.type === 1)
          this.userList = res.data.filter(item => item.type === 2)
        })
      } else {
        getAccountRule({ keyword: this.searchKeyWord, account_id: this.singleSelectAccount.account_id }).then(res => {
          this.userGroupList = res.data.filter(item => item.type === 1)
          this.userList = res.data.filter(item => item.type === 2)
        })
      }
    },
    handleCheckAllChange(val) {
      this.checkedUserGroup = val ? this.userGroupList.map(item => item.id) : []
      this.checkedUser = val ? this.userList.map(item => item.id) : []
      this.isIndeterminate = false
    },
    handleCheckedUserChange(value) {
      let checkedCount = value.length
      this.isIndeterminate = checkedCount > 0 && this.checkedUserGroup.length + this.checkedUser.length < this.userGroupList.length + this.userList.length
      this.isCheckAll = this.userGroupList.length + this.userList.length === this.checkedUserGroup.length + this.checkedUser.length
    },
    divideAccount(row) {
      this.dialogType = 'divide'
      this.divideDialogVisible = true
      this.singleSelectAccount = row
      getAccountRule().then(res => {
        this.userGroupList = res.data.filter(item => item.type === 1)
        this.userList = res.data.filter(item => item.type === 2)
      })
      getAccountRule({ account_id: row.account_id }).then(res => {
        this.checkedUserGroup = res.data.filter(item => item.type === 1 && item.rule === 1).map(item => item.id)
        this.checkedUser = res.data.filter(item => item.type === 2 && item.rule === 1).map(item => item.id)
        this.isIndeterminate = this.checkedUserGroup.length + this.checkedUser.length > 0 && this.checkedUserGroup.length + this.checkedUser.length < this.userGroupList.length + this.userList.length
        this.isCheckAll = this.userGroupList.length + this.userList.length === this.checkedUserGroup.length + this.checkedUser.length
      })
    },
    batchDivideAccount() {
      this.dialogType = 'batchDivide'
      this.divideDialogVisible = true
      getAccountRule().then(res => {
        this.userGroupList = res.data.filter(item => item.type === 1)
        this.userList = res.data.filter(item => item.type === 2)
      })
    },
    devideFormClose() {
      this.divideDialogVisible = false
      this.checkedUserGroup = []
      this.checkedUser = []
      this.isIndeterminate = false
      this.isCheckAll = false
      this.searchKeyWord = ''
      this.userGroupList = []
      this.userList = []
      this.singleSelectAccount = {}
    },
    devideSumBtn() {
      if (this.dialogType === 'divide') {
        editAccountRule({
          account_ids: this.singleSelectAccount.account_id,
          user_group_ids: this.checkedUserGroup.join(','),
          user_ids: this.checkedUser.join(',')
        }).then(res => {
          this.$message.success("账户分配成功")
          this.devideFormClose()
        })
      } else {
        editAccountRule({
          account_ids: this.checkTableList.map(item => item.account_id).join(','),
          user_group_ids: this.checkedUserGroup.join(','),
          user_ids: this.checkedUser.join(',')
        }).then(res => {
          this.$message.success("账户批量分配成功")
          this.devideFormClose()
        })
      }
    },
    labelCancelBtn() {
      this.currentAccountId = ''
      this.labelSelect = ''
    },
    // 关联标签
    labelSumBtn(row) {
      api.facebook.addLabel({
        label_name: JSON.stringify(this.labelSelect),
        account_id: row.account_id
      }).then(res => {
        this.$message.success('关联标签成功')
        this.labelSelectOptions = res.data
        row.label = this.labelSelect.map(item => ({ id: item, name: item }))
        this.labelCancelBtn()
      })
    },
    // 点击添加标签按钮
    addLabel(row) {
      this.currentAccountId = row.account_id
      this.labelSelect = row.label.map(item => item.name)
      this.$nextTick(() => {
        this.$refs[`popover-${row.account_id}`].$refs.popperRef.style.display = 'none'
      })
    },
    deleteLabel(row, id) {
      const label = row.label.filter(item => item.id !== id).map(item => item.name)
      api.facebook.addLabel({
        label_name: JSON.stringify(label),
        account_id: row.account_id,
      }).then(res => {
        this.$message.success('删除标签成功')
        this.labelSelectOptions = res.data
        row.label = row.label.filter(item => item.id !== id)
      })
    },
    // 点击批量添加标签按钮
    batchAddLabel() {
      for (let index = 0; index < this.checkTableList.length; index++) {
        if (this.checkTableList[index].label.length >= 5) {
          this.$message.warning(`账号${this.checkTableList[index].account_id}标签数量已达上限`)
          return
        }
      }
      this.addLabelDialogVisible = true
    },
    // 批量添加标签
    batchAddLabelSumBtn() {
      if (!this.singleLabel) return this.$message.warning('请选择或新增标签')
      api.facebook.batchAddLabel({
        label_name: this.singleLabel,
        account_ids: JSON.stringify(this.checkTableList.map(item => item.account_id))
      }).then(res => {
        let selectedLabel = {}
        let selectedLabelName = this.singleLabel
        this.labelSelectOptions.forEach(item => {
          if (item.name == selectedLabelName) selectedLabel = item
        })

        this.$message.success('批量新增标签成功')
        this.labelSelectOptions = res.data
        this.addLabelDialogVisible = false
        this.checkTableList.forEach(item => {
          if (!item['label']) {
            item['label'] = [selectedLabel]
          } else {
            let existed = 0
            item['label'].forEach(label => {
              if (label.id == selectedLabel.id) existed = 1
            })

            if (!existed) item['label'].push(selectedLabel)
          }
        })

        this.checkTableList.map(item => item.account_id)
        this.getAllAccountList()
      })
    },
    getColumnWidth(column) {
      const labelList = this.allAccountList.map(item => item[column].reduce((acc, obj) => acc + obj.name.length, 0))
      const labelLengthList = this.allAccountList.map(item => item[column].length)
      if (Math.max(...labelList) === 4 && Math.max(...labelLengthList) === 4) return 300
      return (Math.max(...labelList) * 20 + 180) > 1100 ? 1100 : (Math.max(...labelList) * 20 + 180)
    },
    filterLabel(arr) {
      if (arr[arr.length - 1].length > 15) {
        this.$message.warning('标签限制最多15个字符')
        this.labelSelect = arr.slice(0, arr.length - 1)
      }
    },
    filterBatchLabel(str) {
      if (str.length > 15) {
        this.$message.warning('标签限制最多15个字符')
        this.singleLabel = ''
      }
    },
    rolePermission(node) {
      if (this.$store.getters.userInfo.manage === 1) return true
      let permission = this.accountPermissionPage || this.$store.getters.rolePermission
      let result = permission.includes(node)
      return result
    },
    checkFBGuidance() {
      api.common.judgeFBGuidance().then(res => {
        if (res.type === 1) {
          this.guidance.text = res.msg
          this.guidance.url = res.url
          this.guidance.show = true
          this.guidance.title = res.title
          this.guidance.help = res.help
          api.common.submitFBGuidance(1, this.guidance.url)
        }
      })
    },
    goFBGuidance(type, url) {
      filters.gotoPage(url)
      api.common.submitFBGuidance(type, url)
    }
  }
}
</script>

<style lang="less">
.divide-dialog {
  .el-dialog__body {
    padding-bottom: 0 !important;
  }

  .el-dialog__footer {
    padding-top: 0 !important;
    padding-bottom: 40px !important;
  }

  .add-label-select {
    width: 100%;

    .el-input__inner {
      background: #FAFBFC;
      border: 1px solid #D9D9D9
    }
  }
}

.fb-all-account-label-popover {
  padding: 20px !important;

  .container {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 14px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: #262626;
      margin-bottom: 20px;
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      margin-top: 40px;
    }
  }
}
</style>
<style lang="less" scoped>
.el-icon-edit-outline {
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;
}

.account-form {
  :deep(.el-button) {
    font-size: 14px;
    font-family: 'PingFangSC-Regular';
  }

  .first-row {
    width: 100%;
    gap: 24px;

    :deep(.el-form-item:not(:first-child)) {
      flex: 1;
    }

    :deep(.el-form-item) {
      margin-right: 0;
    }

    :deep(.el-select) {
      width: 100%;
    }
  }

  :deep(.el-form-item) {
    margin-bottom: 14px;
  }

  :deep(.el-form-item__label) {
    padding-right: 8px;
  }

  .last-row {
    display: flex;

    :deep(.el-form-item) {
      margin-bottom: 20px;
    }

    :deep(.el-select) {
      width: 100px;
    }

    :deep(.el-date-editor) {
      width: 100%;
    }

    :deep(.el-form-item:nth-child(2)) {
      flex: 1;
      max-width: 400px;
    }
  }
}

.downLoadRow {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 24px;

  :deep(.el-form-item) {
    flex: 1;
    margin-right: 0;
  }

  #downLoad {
    position: absolute;
    right: 0;
  }
}

.downLoadForm {
  .el-checkbox {
    width: 100px;
  }

  :deep(.el-transfer__button) {
    display: block;
    min-height: 32px;
    padding: 9px 15px;
    font-size: 12px;
  }

  :deep(.el-transfer__button:nth-child(2)) {
    margin-left: 0 !important;
  }
}

:deep(thead tr th .cell) {
  color: #262626;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
}


.UserGuideWrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  z-index: 5;

  .GuideRange {
    position: absolute;
    top: 564px;
    left: 268px;
    width: 200px;
    height: 60px;
  }
}

.listWarning {
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;
}

.el-alert--info.is-light {
  background-color: rgba(43, 130, 255, 0.04);
  border: none;
  margin-bottom: 16px;
  border-radius: 0%;
}

:deep(.el-alert__description) {
  color: #8c8c8c !important;
}

.alert-blue::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: #2b82ff;
}

.alert-yellow {
  background-color: rgba(255, 185, 0, 0.08) !important;
  cursor: pointer;


  :deep(.el-alert__title.is-bold) {
    display: flex;
    align-items: center;

    .el-button {
      margin-left: 5px;
      padding: 5px 15px !important;
      min-height: 20px !important;
      color: #FFB900;
      border-color: #FFB900;
      background-color: rgba(0, 0, 0, 0) !important;
    }

    .el-button:hover {
      background-color: #FFB900 !important;
      color: #FFFFFF;
    }
  }

  :deep(.title-img) {
    align-self: center;
    width: 16px;
    height: 16px;
    margin-left: 4px;

    .title-img:hover {
      fill: #2B82FF;
    }
  }

  :deep(.el-alert__description) {
    .guidance-text {
      line-height: 20px;
    }

    .guidance-text a {
      align-self: end !important;
    }

    a {
      position: relative;
      font-weight: bolder;
      font-size: 12px;
      color: #FFB900;
      line-height: 20px;
      text-decoration: none;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 1px;
        background-color: #FFB900;
      }
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      object-fit: cover;
    }
  }
}

.alert-yellow::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: #FFb900;
}

:deep(.el-alert__icon) {
  background-color: #e6f7ff !important;
  color: #2b82ff !important;
}

:deep(.el-alert__title.is-bold) {
  font-size: 16px;
  font-weight: 400;
  color: #595959;
}

.articleLink {
  color: #2b82ff;
  text-decoration: none;
  cursor: pointer;
}

.select-account {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #262626;
  line-height: 40px;
}

.divide-dialog-container {
  background: #FAFBFC;
  border-radius: 2px;
  border: 1px solid #D9D9D9;

  .account-list {
    height: 132px;
    overflow: auto;
    padding-left: 20px;
    padding-top: 8px;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #C1C1C1;
    }

    span {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 22px;
      margin-bottom: 8px;
    }
  }

  .user-search {
    padding: 16px 20px 0 20px
  }

  .user-list {
    height: 200px;
    overflow: auto;
    padding-left: 20px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #C1C1C1;
    }

    :deep(.el-checkbox) {
      display: block;
      height: 30px;
    }

    :deep(.el-checkbox-group .el-checkbox) {
      display: block;
      margin-left: 24px;
      height: 30px;

      .el-checkbox__label {
        width: 350px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

#secondRow :deep(.el-button) {
  font-size: 14px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
}

#secondRow :deep(.is-disabled) {
  border: 1px solid #D9D9D9;
  font-size: 14px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  color: #595959;
}

.label-popover {
  display: flex;
  align-items: center;
  gap: 4px;

  .add-label-button {
    height: 24px;
    line-height: 24px;
    padding: 0 8px;
    font-size: 12px;
    font-family: 'PingFangSC-Regular';
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    background: #ECF5FF;
    border: 1px solid #409EFF;
    color: #2b82ff;
    cursor: pointer;
  }
}
</style>
