<template>
    <el-card v-if="!accountBannerClosed">
        <el-carousel :interval="5000" arrow="never" indicator-position="none">
            <el-carousel-item @click="goMeta">
                <img src="@/assets/images/accountList/banner-meta.svg" alt="">
            </el-carousel-item>
            <el-carousel-item @click="goTemplate">
                <img src="@/assets/images/accountList/banner-template.svg" alt="">
            </el-carousel-item>
            <el-carousel-item @click="goBot">
                <img src="@/assets/images/accountList/banner-bot.svg" alt="">
            </el-carousel-item>
            <el-carousel-item @click="goAi">
                <img src="@/assets/images/accountList/banner-ai.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item @click="goRule">
                <img src="@/assets/images/accountList/banner-rule.svg" alt="">
            </el-carousel-item>
        </el-carousel>
        <img src="@/assets/images/adTemplate/close-icon.svg" alt="close" class="close" @click="closeBanner" />
    </el-card>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
import api from '@/request/api/ad'

const { proxy } = getCurrentInstance()

const accountBannerClosed = ref(localStorage.getItem('accountBannerClosed'))

const goMeta = () => {
    window.open('https://chinaexportsummit2024ecom.splashthat.com/?full_url=https%3A%2F%2Fchinaexportsummit2024ecom.splashthat.com%2F', '_blank')
}

const goTemplate = () => {
    api.template.handleEventTracking({ type: 15 }).finally(() => {
        proxy.$router.push('/ad_template')
    })
}

const goBot = () => {
    api.template.handleEventTracking({ type: 16 }).finally(() => {
        window.open('https://messenger.yinobot.com/login', '_blank')
    })
}

const goAi = () => {
    api.template.handleEventTracking({ type: 18 }).finally(() => {
        proxy.$router.push('/piccopilot')
    })
}

const goRule = () => {
    api.template.handleEventTracking({ type: 17 }).finally(() => {
        proxy.$router.push('/ad_template')
    })
}

const closeBanner = () => {
    localStorage.setItem('accountBannerClosed', 'true')
    accountBannerClosed.value = true
}

</script>

<style lang="less" scoped>
.el-card {
    position: relative;
    margin-bottom: 20px;
    border-radius: 6px;
    border: none;
    overflow: hidden;

    :deep(.el-card__body) {
        padding: 0;
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 16px;
        height: 16px;
        cursor: pointer;
    }
}

:deep(.el-carousel) {
    width: 100%;
    aspect-ratio: 1184 / 60;

    .el-carousel__container {
        height: 100%;
    }
}

:deep(.el-carousel__item) {
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
    }
}
</style>