<template>
  <div style="position:relative;">
    <el-tabs v-model="activeName" class="historyTabs">
      <el-tab-pane label="" name="facebook">
        <template #label>
          <span id="firstStep">Facebook</span>
        </template>
        <FBOpenHistory v-if="activeName === 'facebook'"/>
      </el-tab-pane>
      <el-tab-pane label="TikTok" name="tiktok">
        <TTOpenHistory v-if="activeName === 'tiktok'"/>
      </el-tab-pane>
      <el-tab-pane label="微软广告" name="bing">
        <BingHistory v-if="activeName === 'bing'"/>
      </el-tab-pane>
      <el-tab-pane label="Google" name="Google">
        <GGOpenHistory v-if="activeName === 'Google'"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import FBOpenHistory from './FBOpenHistory.vue'
import TTOpenHistory from './TTOpenHistory.vue'
import GGOpenHistory from './google/GGOpenHistory.vue'
import BingHistory from '@/views/account/history/bing/Index'

import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import $ from 'jquery'

export default {
  name: 'AccountHistory',
  components: {FBOpenHistory, TTOpenHistory, GGOpenHistory, BingHistory},
  props: ['tabsType'],
  data() {
    return {
      activeName: '',
      driverOpenHistory: null,
      intro: '',
      intervalDriver: null
    }
  },
  mounted() {
    // this.getBindEmail()
    this.ttTabCheck()
  },
  beforeUnmount() {
    if (this.driverOpenHistory != null) {
      this.driverOpenHistory.reset()
    }
  },
  methods: {
    async getBindEmail() {
      const {data: res} = await this.$http.post('cloudfb/set_email')
      this.intro = res.data.intro
      if (this.intro == 1) {
        this.openHistoryGuide()
      }
    },
    ttTabCheck() {
      if (this.tabsType === 'tiktok') {
        this.activeName = 'tiktok'
      } else if (this.tabsType === 'Google') {
        this.activeName = 'Google'
      } else if (this.tabsType === 'bing') {
        this.activeName = 'bing'
      } else {
        this.activeName = 'facebook'
      }
    },
    AddScroll() {
      var top = $('.el-main').scrollTop()
      $('.el-main').on('scroll.unable', function (e) {
        $('.el-main').scrollTop(top)
      })
    },
    RemoveScroll() {
      $('.el-main').unbind('scroll.unable')
    },
    openHistoryGuide() {
      this.$nextTick(() => {
        this.driverOpenHistory = new Driver({
          allowClose: false,
          opacity: 0,
          nextBtnText: '下一步',
          closeBtnText: '跳过',
          doneBtnText: '完成',
          // closeBtnText: '完成',
          onReset: () => {
            this.RemoveScroll()
            this.$http.post('user/set_intro', {
              type: 'account_history'
            })
          },
          onHighlightStarted: () => {
            this.$nextTick(() => {
              this.AddScroll()
              $('#firstStep').css('pointer-events', 'none')
            })
          },
          onNext: () => {
            this.RemoveScroll()
          }
        })

        this.driverOpenHistory.defineSteps([
          {
            element: '#firstStep',
            popover: {
              className: 'first-step-popover-class',
              title: '新手指引(1/2)',
              description: '点击标签即可切换查看Facbook和TikTok的开户历史及账户数量',
              position: 'bottom',
              closeBtnText: ''
            }
          },
          {
            element: '#secondStep',
            popover: {
              title: '新手指引(2/2)',
              description: '您也可以通过邮箱订阅开户通知，订阅后，您将在下户后的第一时间内，收到一条邮件提醒',
              position: 'left',
              closeBtnText: ''
            }
          }
        ])
        this.driverOpenHistory.start()
        // this.driverOpenHistory.highlight({
        //   element: '#firstStep',
        //   popover: {
        //     title: '新功能指引',
        //     description: '点击标签即可切换查看Facbook和TikTok的开户历史及账户数量',
        //     position: 'bottom'
        //   }
        // })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.historyTabs {
  :deep(.el-tabs__header) {
    background: #fff;

    .el-tabs__nav-wrap {
      padding-left: 20px;
    }
  }
}
</style>
