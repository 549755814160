<template>
  <!-- 账户信息 -->
  <el-row style="margin-bottom: 20px" class="firstRow">
    <el-col>
      <el-card>
        <ul class="pastAccount">
          <li>
            <i class="iconfont icon-zonglan"></i>
            <div>
              <p class="Text" style="margin: 0">账户总数</p>
              <div class="MainHead">{{ total_num }}</div>
            </div>
          </li>
          <li>
            <i class="iconfont icon-jinzhide"></i>
            <div>
              <p class="Text" style="margin: 0">被封账户数</p>
              <div class="MainHead">{{ blocked_num }}</div>
            </div>
          </li>
          <li>
            <i class="iconfont icon-qunfengzhanghuguanli"></i>
            <div>
              <p class="Text" style="margin: 0">闲置账户数</p>
              <div class="MainHead">{{ idle_num }}</div>
            </div>
          </li>
          <li>
            <i class="iconfont icon-jinxingzhong"></i>
            <div>
              <p class="Text" style="margin: 0">正在开户中</p>
              <div class="MainHead">{{ review_num }}</div>
            </div>
          </li>
        </ul>
      </el-card>
    </el-col>
  </el-row>

  <!-- 开户历史 -->
  <el-row>
    <el-col>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span class="SubHead">Facebook开户历史</span>
            <router-link to="/subMsg"><span style="font-size: 14px; color: #2b82ff">订阅开户通知</span></router-link>
            <!-- <el-button class="subNotice" type="text"  @click="subOpenNotice">
              <span v-if="this.sub_type == 0" class="brandColor" id="secondStep">订阅开户通知</span>
              <span v-else class="successColor">新增邮箱（已订阅）</span>
            </el-button> -->
          </div>
        </template>
        <el-form ref="historyFormRef" :inline="true" :model="historyForm" style="padding-top: 15px">
          <el-form-item label="选择日期" prop="timeRange">
            <el-date-picker v-model="historyForm.timeRange" type="daterange" size="small" start-placeholder="开始日期"
              end-placeholder="结束日期" value-format="X" style="margin-left: 14px"></el-date-picker>
          </el-form-item>
          <el-form-item label="OE编号" prop="oe_id">
            <el-input placeholder="请输入OE编号" size="small" v-model="historyForm.oe_id" clearable></el-input>
          </el-form-item>
          <el-form-item label="营业执照" prop="company">
            <el-input placeholder="请输入营业执照" size="small" v-model="historyForm.company" clearable></el-input>
          </el-form-item>
          <el-form-item label="账户状态" prop="status">
            <el-select v-model="historyForm.status" placeholder="请选择状态" size="small" clearable>
              <el-option label="审核中" value="1"></el-option>
              <el-option label="已获批" value="2"></el-option>
              <el-option label="未获批" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left: 14px">
            <el-button type="primary" @click="queryFormBtn" size="small">查询</el-button>
            <el-button @click="resetFormBtn" size="small">重置</el-button>
          </el-form-item>
        </el-form>

        <el-alert title="提示" description="" type="info" show-icon>
          <template #default>
            若超过一个工作日您的开户申请审核状态仍未更新，请寻求人工客服的帮助。<br />
            若您未能查询到您提交的开户申请记录，请在提交开户申请后第二天早上8点后点击校验营业执照进行审核校验。
            <el-button type="text" class="LinkText" @click="checkLicense" size="small">校验营业执照</el-button>
          </template>
        </el-alert>

        <el-table :data="historyList" style="width: 100%" :header-cell-style="{
          background: 'var(--el-table-row-hover-background-color)',
        }">
          <el-table-column property="oe_id" label="OE编号" fixed min-width="180" />
          <el-table-column property="chinese_legal_entity_name" label="营业执照名称" min-width="160" />
          <el-table-column property="promotable_urls" label="推广链接" min-width="160">
            <template #default="{ row }">
              <span v-html="row.promotable_urls"></span>
            </template>
          </el-table-column>
          <el-table-column property="promotable_page_urls" label="Facebook主页" min-width="320">
            <template #default="{ row }">
              <span v-html="row.promotable_page_urls"></span>
            </template>
          </el-table-column>
          <el-table-column label="状态" min-width="130">
            <template #default="{ row }">
              <el-tag type="success" v-if="row.status_type == 1">{{ row.status }}</el-tag>
              <el-tag type="danger" v-else-if="row.status_type == 0">{{ row.status }}</el-tag>
              <el-tag v-else>{{ row.status }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column property="reason" label="备注" min-width="200">
            <template #default="{ row }">
              <span>
                {{ row.reason }}
              </span>
              <div v-if="row.url != ''">
                <el-image style="width: 50px; height: 50px" :src="row.url" :preview-src-list="row.urls" @click.stop="">
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column property="time_created" label="提交时间" min-width="150" />
          <el-table-column property="ad_accounts" label="广告账户ID" width="200" />
          <el-table-column label="操作" min-width="130" fixed="right">
            <template #default="{ row }">
              <el-button type="text" class="LinkText" v-show="row.status == '需要修改'"
                @click="goEditLink(row.token_id)">修改</el-button>
            </template>
          </el-table-column>
          <template #empty>
            <img src="@/assets/empty.gif" alt="" style="width: 400px; height: 400px" />
          </template>
        </el-table>
        <!-- 分页器 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.page" :page-sizes="[5, 10]" :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper" :total="total" background>
        </el-pagination>
      </el-card>
    </el-col>
  </el-row>

  <!-- 订阅开户管理通知时验证邮箱是否绑定 -->
  <el-dialog v-model="noticeDialogVisible" @close="noticeDialogClose" title="订阅开户通知" width="600px"
    custom-class="subDialog">
    <p v-if="this.sub_type == 0">是否确认订阅开户通知，订阅后，您将在下户后的当天内，收到一条邮件提醒。</p>
    <p v-else>您已订阅开户通知，如需多个邮箱接收，请在下方新增。</p>
    <el-form ref="confirmEmailFormRef" :rules="confirmEmailFormRules" :model="confirmEmailForm">
      <el-form-item prop="emailValue">
        <el-select v-model="confirmEmailForm.emailValue" multiple filterable allow-create default-first-option
          :reserve-keyword="false" placeholder="请输入邮箱号码" style="width: 100%">
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" type="primary" @click="confirmNoticeBtn">确定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- FB开户历史修改 -->
  <el-dialog v-model="licenseDialogVisible" @close="licenseDialogClose" title="营业执照修改" custom-class="createFB"
    width="800px">
    <el-alert title="帮助提示" description="" type="info" show-icon>
      <template #default> 如果需要修改营业执照，请输入新的营业执照进行校验，如果不是点取消即可 </template>
    </el-alert>
    <!-- 内容主体区域 -->
    <el-form ref="licenseFormRef" :model="licenseForm" :rules="licenseFormRules" label-width="100px" style="width: 50%">
      <el-form-item label="营业执照" prop="licenseNum">
        <el-input size="small" placeholder="请输入营业执照名称" clearable v-model.trim="licenseForm.licenseNum"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="licenseFormClose">取 消</el-button>
        <el-button size="small" type="primary" @click="licenseFormBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 校验营业执照 -->
  <el-dialog v-model="checkLicenseVisible" @close="checkDialogClose" title="检验营业执照" custom-class="checkLicense"
    width="800px">
    <el-alert title="提示" description="" type="info" show-icon :closable="false">
      <template #default> 请输入您在OE提交的完整的营业执照名称 </template>
    </el-alert>
    <el-form ref="checkFormRef" :model="checkForm" :rules="checkFormRules" label-width="100px">
      <el-form-item label="营业执照" prop="licenseNum">
        <el-select v-model.trim="checkForm.licenseNum" filterable default-first-option allow-create
          placeholder="请输入正确且完整的营业执照名称" size="small" style="width: 270px;">
          <el-option v-for="item in entityNameOptions" :key="item" :label="item" :value="item" />
        </el-select>
      </el-form-item>
    </el-form>
    <el-row type="flex" class="upload-row">
      <upload-file ref="uploadImgRef" :span="12" :action="uploadURL" :limit="1" :url="licenseImg" upload-text="点击上传营业执照"
        :preview-src-list="licenseImg" @handleRemove="licenseImg = ''" :editable="true"
        @handleSuccess="handleSuccess($event, true)" />
    </el-row>
    <el-row>
      <span class="uploadTip">注：支持通过上传文件识别出营业执照</span>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="checkFormClose">取 消</el-button>
        <el-button size="small" type="primary" @click="checkLicenseBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessage } from 'element-plus'
import base from "@/request/base";
import api from "@/request/api/account";
import UploadFile from "@/views/userinfo/approve/client/UploadFile";

export default {
  name: 'FBOpenHistory',
  components: {
    UploadFile
  },
  data() {
    //浏览器高度
    var height = window.innerHeight
    //浏览器宽度
    var width = window.innerWidth
    //浏览器
    // 浏览器检查结果
    let browserInfo = getBrowserInfo()
    var browser = browserInfo.browserName + '的版本是：' + browserInfo.versionInfo
    function getBrowserInfo() {
      let sysObj = {}
      let browserInfo = navigator.userAgent.toLowerCase()
      let regular = /(msie|firefox|chrome|opera|version).*?([\d.]+)/
      let vsMsg = browserInfo.match(regular)
      sysObj.browserName = vsMsg[1].replace(/version/, "'safari")
      sysObj.versionInfo = vsMsg[2]
      return sysObj
    }
    // 获取操作系统信息
    function getOsInfo() {
      var userAgent = navigator.userAgent.toLowerCase()
      var name = 'Unknown'
      var version = 'Unknown'
      if (userAgent.indexOf('win') > -1) {
        name = 'Windows'
        if (userAgent.indexOf('windows nt 5.0') > -1) {
          version = 'Windows 2000'
        } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
          version = 'Windows XP'
        } else if (userAgent.indexOf('windows nt 6.0') > -1) {
          version = 'Windows Vista'
        } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
          version = 'Windows 7'
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
          version = 'Windows 8'
        } else if (userAgent.indexOf('windows nt 6.3') > -1) {
          version = 'Windows 8.1'
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
          version = 'Windows 10'
        } else {
          version = 'Unknown'
        }
      } else if (userAgent.indexOf('iphone') > -1) {
        name = 'Iphone'
      } else if (userAgent.indexOf('mac') > -1) {
        name = 'Mac'
      } else if (
        userAgent.indexOf('x11') > -1 ||
        userAgent.indexOf('unix') > -1 ||
        userAgent.indexOf('sunname') > -1 ||
        userAgent.indexOf('bsd') > -1
      ) {
        name = 'Unix'
      } else if (userAgent.indexOf('linux') > -1) {
        if (userAgent.indexOf('android') > -1) {
          name = 'Android'
        } else {
          name = 'Linux'
        }
      } else {
        name = 'Unknown'
      }
      return { name, version }
    }
    var os = getOsInfo().name
    if (os == 'Windows') {
      os = getOsInfo().version
    }
    // 邮箱集验证规则
    const emailValidRule = (rule, value, cb) => {
      let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      value.forEach((item) => {
        if (!reg.test(item)) {
          cb(new Error('邮箱格式不正确'))
        }
      })
      return cb()
    }
    return {
      // 客户端信息
      width: width,
      height: height,
      browser: browser,
      os: os,
      userInfo: '',
      token: '',
      active_rate: '',
      total_num: '',
      blocked_num: '',
      idle_num: '',
      review_num: '',
      // 表单查询
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5,
      },
      total: 0,
      historyForm: {
        timeRange: '',
        oe_id: '',
        company: '',
        status: '',
      },
      historyList: [],
      // 营业执照校验对话框
      licenseDialogVisible: false,
      licenseForm: {
        licenseNum: '',
      },
      uploadURL: `${base.admin_url}/cloud/file/uploadfile_tt`,
      entityNameOptions: [], // 营业执照关联历史列表
      licenseImg: '', // 营业执照图片
      licenseFormRules: {
        licenseNum: [
          { required: true, message: '请输入营业执照', trigger: 'blur' },
          {
            pattern: /^[\u4e00-\u9fa5\(\)\（\）]{0,}$/,
            message: '只能使用中文营业执照开户',
            trigger: 'change',
          },
        ],
      },
      token_id: '',
      noticeDialogVisible: false,
      confirmEmailForm: {
        emailValue: [],
      },
      confirmEmailFormRules: {
        emailValue: [{ validator: emailValidRule, trigger: 'change' }],
      },
      sub_type: 0,
      notesDialogVisible: false,
      notesTableData: [],
      checkLicenseVisible: false,
      checkForm: {
        licenseNum: '',
      },
      checkFormRules: {
        licenseNum: [
          { required: true, message: '请输入营业执照', trigger: 'blur' },
          {
            pattern: /^[\u4e00-\u9fa5\(\)\（\）]{0,}$/,
            message: '只能使用中文营业执照开户',
            trigger: 'change',
          },
        ],
      },
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
    this.getHistoryList()
    // this.getBindEmail()
  },
  methods: {
    queryFormBtn() {
      this.getHistoryList()
    },
    resetFormBtn() {
      this.$refs.historyFormRef.resetFields()
      this.getHistoryList()
    },
    async getBindEmail() {
      const { data: res } = await this.$http.post('cloudfb/set_email')
      this.confirmEmailForm.emailValue = res.data.fb_sub
      this.sub_type = res.data.fb_sub_type
    },
    async getHistoryList() {
      const { data: res } = await this.$http.post('cloudfb/account_history', {
        page: this.queryInfo.page,
        pagesize: this.queryInfo.pagesize,
        timeRange: this.historyForm.timeRange,
        oe_id: this.historyForm.oe_id,
        company: this.historyForm.company,
        status: this.historyForm.status,
      })
      if (res.code == 300) {
        this.$router.push('/login')
      }
      if (res.code == 100) {
        this.$message.error(`${res.msg}`)
        this.$router.push('/clientInfo')
      }
      this.historyList = res.list
      this.active_rate = res.data.active_rate
      this.total = res.data.total
      this.total_num = res.data.total_num
      this.blocked_num = res.data.blocked_num
      this.idle_num = res.data.idle_num
      this.review_num = res.data.review_num
      this.token = res.data.token
    },
    // 展示条数改变触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getHistoryList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getHistoryList()
    },
    subOpenNotice() {
      this.noticeDialogVisible = true
      if (this.driverOpenHistory != null) {
        this.driverOpenHistory.reset()
      }
    },
    noticeDialogClose() { },
    confirmNoticeBtn() {
      this.$refs.confirmEmailFormRef.validate(async (valid) => {
        if (!valid) return
        var emailValue = this.confirmEmailForm.emailValue.join(',')
        const { data: res } = await this.$http.post('cloudfb/sub', {
          email: emailValue,
          type: 'fb_account_email',
        })
        if (res.code == 100) return this.$message.error(`${res.msg}`)
        if (res.code == 300) {
          this.$router.push('/login')
        }
        this.$message.success(`${res.msg}`)
        this.noticeDialogVisible = false
        this.$router.push({
          name: 'account_history',
        })
        this.getBindEmail()
      })
    },
    goEditLink(token_id) {
      this.token_id = token_id
      this.licenseDialogVisible = true
    },
    licenseDialogClose() {
      this.$refs.licenseFormRef.resetFields()
    },
    licenseFormClose() {
      window.open(`https://www.facebook.com/chinabusinesses/onboarding/220398642106724/?token=${this.token_id}`)
      this.licenseDialogVisible = false
    },
    licenseFormBtn() {
      this.$refs.licenseFormRef.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.post('cloudfb/account_check', {
          entity_name: this.licenseForm.licenseNum,
          height: this.height,
          width: this.width,
          browser: this.browser,
          os: this.os,
        })
        if (res.code == 100) {
          return this.$message.error(`${res.msg}`)
        }
        if (res.code == 300) {
          this.$router.push('/login')
        }
        window.open(`https://www.facebook.com/chinabusinesses/onboarding/220398642106724/?token=${this.token_id}`)
        this.licenseDialogVisible = false
      })
    },
    checkDialogClose() { },
    checkLicense() {
      this.checkLicenseVisible = true
      api.facebook.getHistoryLicense().then(res => {
        this.entityNameOptions = res.data
      })
    },
    checkFormClose() {
      this.checkLicenseVisible = false
      this.$refs.checkFormRef.resetFields()
    },
    checkLicenseBtn() {
      this.$refs.checkFormRef.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.post('cloudfb/account_check', {
          entity_name: this.checkForm.licenseNum,
          height: this.height,
          width: this.width,
          browser: this.browser,
          os: this.os,
        })
        if (res.code == 100) {
          return this.$message.error(`${res.msg}`)
        }
        if (res.code == 300) {
          this.$router.push('/login')
        }
        this.$message.success(`检验成功`)
        this.$refs.checkFormRef.resetFields()
        this.checkLicenseVisible = false
      })
    },
    /** 上传营业执照识别回调 */
    handleSuccess(res, updateCode = true, field = undefined) {
      if (res.code === '100') ElMessage.error(res.msg)
      if (res.code === '200') {
        this.checkForm.licenseNum = res.license_name
      }
    }
  },
}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}

.createFB .el-alert--info.is-light {
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff !important;
  margin-bottom: 20px;
}

.fillGuide {
  color: #2b82ff;
  vertical-align: baseline !important;
  margin-left: 5px;
}

.pastAccount {
  display: flex;
  justify-content: space-evenly;

  li {
    position: relative;
    width: 25%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-right: 1px solid #d9d9d9;

    i.iconfont {
      font-size: 50px;
      color: #2b82ff;
    }

    i.el-icon-warning {
      position: absolute;
      top: 1px;
      right: 22px;
      font-size: 18px;
      color: #909399;
      cursor: pointer;
    }

    div {
      width: 50%;
    }
  }

  li:last-child {
    border: none;
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.firstRow {
  :deep(.el-card__body) {
    padding: 36px 20px;
  }
}

.el-alert--info.is-light {
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff !important;
  margin-bottom: 20px;
}

:deep(.el-alert__icon) {
  background-color: #e6f7ff !important;
  color: #2b82ff !important;
}

:deep(.el-alert__title.is-bold) {
  font-size: 16px;
  font-weight: 400;
  color: #51575a;
}

.upload-row {
  padding-left: 100px;

  :deep(.el-upload--picture-card) {
    float: left;
    margin-right: 20px;
  }

  :deep(.el-upload-list__item) {
    width: 180px;
    height: 180px;
  }
}

.uploadTip {
  color: #262626;
  margin-top: 16px;
  margin-left: 100px;
}
</style>
