<template>
  <div>
    <el-row class="firsrRow">
      <el-col :span="16">
        <GGAccountState :accountStateItem="accountStateItem"></GGAccountState>
      </el-col>

      <el-col :span="8">
        <GGUsableBalance />
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span class="SubHead">所有账户( {{ totalNum }} )</span>
            </div>
          </template>
          <GGAllAccount />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import GGAccountState from './GGAccountState.vue'
import GGUsableBalance from './GGUsableBalance.vue'
import GGAllAccount from './GGAllAccount.vue'

export default {
  name: 'GGAccountList',
  components: {
    GGAccountState,
    GGUsableBalance,
    GGAllAccount
  },
  data() {
    return {
      totalNum: '',
      accountStateItem: {
        title: '',
        isshow: false,
        accountState: []
      }
    }
  },
  mounted() {
    this.getAllAccountList()
  },
  methods: {
    // 获取列表数据
    async getAllAccountList() {
      const { data: res } = await this.$http.post('cloudgg/ad_data')
      if (res.code == 300) {
        this.$router.push('/login')
      }
      if (res.code == 100) {
        this.$message.error(`${res.msg}`)
        this.$router.push('/clientInfo')
      }
      this.accountStateItem = res.data.accountStateItem
      this.totalNum = res.data.total_num
    }
  }
}
</script>

<style lang="less" scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:deep(.el-tabs__header) {
  margin-bottom: 30px;
}

.firsrRow {
  margin-bottom: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  :deep(.el-card) {
    border: none;
    box-shadow: none;
  }

  .el-col-16 :deep(.el-card) {
    border-radius: 0 0 0 4px;
    border-right: 1px solid #e4e7ed;
  }

  .el-col-8 :deep(.el-card) {
    border-radius: 0 4px 4px 0;
  }
}

i.el-icon-warning {
  font-size: 18px;
  color: #909399;
  cursor: pointer;
}

:deep(.el-tabs__item) {
  font-size: 16px !important;
}

:deep(.el-dialog__header) {
  padding: 15px 20px !important;
  border-bottom: 1px solid #ebebeb;
}
</style>
