<template>
  <div v-if="status === 1">
    <div class="apply-account">
      <el-row type="flex" style="flex-wrap: nowrap;">
        <search-form ref="searchFormRef" :form-items="items" :fetch-data="fetchData" :default-page-size="5"
          class="search-form" />
        <el-button class="add-cid-button" type="primary" size="small"
          @click="(dialogType = 'company'), (editable = true)" style="height: 32px;margin-top: 4px;">
          新增CID账户
        </el-button>
      </el-row>
      <el-row class="mb-10" v-for="company in data" :key="company.c_id">
        <el-col :span="20" class="company-info mb-10">
          <span>{{ company.c_id }}：{{ company.c_name }}</span>
          <el-button type="primary" plain size="small" v-if="company.accounts.length < 6"
            @click="; (dialogType = 'account'), (editable = true), (selected = company)">
            新增XID账户
          </el-button>
        </el-col>
        <el-col class="account-info" :span="20">
          <div class="account-grey account-header">XID</div>
          <div class="account-grey account-header">XID账户名称</div>
        </el-col>
        <el-col class="account-info" v-for="(account, index) in company.accounts" :span="20" :key="account.x_id">
          <div class="account-white">
            <span>{{ account.x_id }}</span>
          </div>
          <div class="account-white">
            <span>{{ account.x_name }}</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row justify="end" class="pagination">
      <el-pagination v-if="pageData" v-model:page-size="pageData.pagesize" v-model:current-page="pageData.page"
        :total="pageData.total" :page-sizes="[5, 10, 20, 40, 60, 80, 100, 200, 300, 400]" @size-change="pageChange"
        @current-change="pageChange" layout="total, sizes, prev, pager, next, jumper" background />
    </el-row>
  </div>
  <div class="empty-continer" v-if="status === 0">
    <img src="@/assets/images/openAccount/empty.svg" alt="">
    <div class="title">您还没有微软广告账户</div>
    <div class="text">立即新增账户，1分钟填写表单，最快当天下户!</div>
    <el-button class="add-cid-button" type="primary" size="small" @click="(dialogType = 'company'), (editable = true)"
      style="margin-top: 0;">
      新增CID账户
    </el-button>
  </div>
  <apply-account-form v-if="dialogType" :editable="editable" :type="dialogType" :company="selected"
    @close="; (dialogType = ''), (selected = {}), (editable = false)" />
</template>
<script setup>
import { getCurrentInstance, ref, onMounted } from 'vue'
import api from '@/request/api/account'
import SearchForm from '@/components/base/SearchForm'
import ApplyAccountForm from '@/views/account/manage/bing/ApplyAccountForm'
import { ElMessage } from 'element-plus'

const { proxy } = getCurrentInstance()
const items = {
  keyword: { label: 'CID/CID账户名称:' },
}

const data = ref([])
const status = ref(null)
const searchFormRef = ref()
const pageData = ref({
  page: 1,
  pagesize: 5,
  total: 0,
})
const fetchData = async (params, withPage = false) => {
  let res = await api.bing.cIdList(
    withPage ? Object.assign(params, pageData.value) : Object.assign(pageData.value, params)
  )
  data.value = res.data
  pageData.value = {
    page: pageData.value.page,
    pagesize: pageData.value.pagesize,
    total: res.total ? res.total : 0,
  }
}

const pageChange = () => {
  fetchData(searchFormRef.value.getSearchForm(), true)
}

const dialogType = ref('')
const selected = ref({})
const editable = ref(false)

onMounted(() => {
  ElMessage({
    message: '由于微软官方账户调整，调整前下户的部分CID账户无法使用，请重新申请开户',
    type: 'warning',
    duration: 5000,
  })
  api.bing.cIdList(
    pageData.value
  ).then(res => {
    if (res.data && res.data.length !== 0) {
      status.value = 1
    } else {
      status.value = 0
    }
  })
})
</script>
<style lang="less" scoped>
.apply-account {
  position: relative;
  min-height: 70vh;

  .search-form {
    width: auto !important;
    margin-right: 40px;

    :deep(.el-form-item__label) {
      color: #262626 !important;
    }
  }
}

.company-info {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #262626;
  display: flex;
  justify-content: space-between;

  :deep(.el-button--small) {
    background: #ffffff;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    border: 1px solid #b3d8ff;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #2b82ff;
    padding: 8px 14px;
  }
}

.account-info {
  margin: 0 0 -1px -1px;
  text-overflow: ellipsis;
  min-height: 40px;
  font-size: 14px;
  display: flex;

  div {
    flex: 1;
  }

  .account-header {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #262626;
  }

  span {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #595959;
  }

  .account-grey:first-child {
    border-right: none;
  }

  .account-white:first-child {
    border-right: none;
  }
}

.account-grey {
  background: #f5f7fa;
  border: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.account-white {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.el-form-item__label) {
  color: #262626;
}

:deep(.el-button--small) {
  padding: 8px 14px;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
}

.pagination {
  position: relative;
  right: 0;
  bottom: 0;
}

.empty-continer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;

  .title {
    font-size: 18px;
    color: #262626;
    line-height: 26px;
    margin-bottom: 8px;
  }

  .text {
    font-weight: 400;
    font-size: 16px;
    color: #595959;
    margin-bottom: 20px;
  }

  :deep(.el-button--primary) {
    margin-top: 20px;
    padding: 8px 14px;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
  }
}
</style>
